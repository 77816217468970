import React from "react"
import { Link, RunStatus, Icon } from "~src/components"
import { routes } from "~src/routes"
import { DateHelper } from "@bonsaichecklist/bonsai-utils"
import { useClickAway } from "react-use"
import { useState } from "~src/store"

interface RunItemProps {
  run: ChecklistRun
  slug: string
  handleDeleteRun?: (runSlug: string) => void
}

export function RunItem({
  run,
  slug,
  handleDeleteRun,
}: RunItemProps): JSX.Element {
  const {
    auth: { user },
  } = useState()

  const ref = React.useRef<HTMLDivElement>(null)

  const [expanded, setExpanded] = React.useState<boolean>(false)

  useClickAway(ref, (e) => {
    if (!expanded) return
    setExpanded(false)
  })

  return (
    <div className="relative">
      <div className="sm:flex flex-center mt-6 p-6 border rounded-md">
        <div>
          <Link href={routes.runs.show(run.slug)}>
            <strong>{run.customizedTitle || run.title}</strong>
          </Link>
          <div className="flex flex-center items-center font-normal text-xs opacity-50 mt-3">
            {run.createdAt && !run.updatedAt && (
              <div className="text-xs opacity-50">
                Created {DateHelper.fromNow(run.createdAt)}
              </div>
            )}
            {run.updatedAt && <>Updated {DateHelper.fromNow(run.updatedAt)}</>}
          </div>
        </div>
        <div className="ml-0 mt-2 sm:mt-0 sm:ml-auto mr-2 flex items-start">
          <RunStatus run={run} />
        </div>
      </div>
      {user && (
        <div className="cursor-pointer" ref={ref}>
          <div
            className="group cursor-pointer px-1 absolute top-6 right-3"
            onClick={() => setExpanded((prev) => !prev)}
          >
            <Icon
              className="text-gray-500 group-hover:text-blue-500"
              name="ellipsis-v"
              size="lg"
              variant="light"
            />
          </div>
          {expanded && (
            <div
              onClick={() => handleDeleteRun(run.slug)}
              style={{ userSelect: "none" }}
            >
              <div
                className={`flex items-center space-x-3 bg-white shadow-md 
          rounded-md absolute py-3 px-4 z-10
            bottom-12  md:bottom-5 
           right-3`}
              >
                <Icon className="text-gray-600" name="trash-alt" size="sm" />
                <span className="font-normal text-xs text-red-600">
                  Delete Checklist
                </span>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
