import React from "react"
import { ATTACHMENT_TILE_SIZE } from "~src/common/constants"
import { Icon, Link } from "~src/components"
import { FileHelper } from "~src/common/lib"
import { useActions } from "~src/store"

export interface AttachmentTileProps {
  attachment: Attachment
  size?: string
  itemSlug: string
  viewOnly?: boolean
}

export function AttachmentTile({
  attachment,
  itemSlug,
  viewOnly,
  size = ATTACHMENT_TILE_SIZE,
}: AttachmentTileProps): JSX.Element {
  const [removing, setRemoving] = React.useState<boolean>(false)
  const {
    checklist: { removeAttachment },
  } = useActions()

  function handleRemoveAttachment(): void {
    setRemoving(true)
    removeAttachment({ itemSlug: itemSlug, attachmentSlug: attachment?.slug })
    setRemoving(false)
  }

  return (
    <div
      className="relative flex flex-col jusitify-content items-center mr-6 mb-6 p-4 shadow-md border border-gray-300 rounded"
      key={attachment.id}
    >
      {!viewOnly && (
        <Link
          className="absolute bg-red-200 hover:bg-red-700 text-red-700 transition duration-200 ease-in-out hover:text-white top-0 right-0 flex items-center justify-center rounded-full text-center w-6 h-6 -mt-3 -mr-3 leading-none"
          disabled={removing}
          onClick={handleRemoveAttachment}
          unstyled
        >
          <Icon name="times" />
        </Link>
      )}
      <Link
        className="text-blue-500 hover:text-blue-800"
        download
        href={attachment.url}
        rel="nofollow noreferrer"
        target="_blank"
      >
        {FileHelper.isImage(attachment.type) && (
          <ImageTile attachment={attachment} removing={removing} size={size} />
        )}
        {FileHelper.isPDF(attachment.type) && <PDFTile size={size} />}
        {FileHelper.isVideo(attachment.type) && (
          <VideoFile url={attachment.url} size={size} />
        )}
        <div
          className="text-sm text-gray-500 mt-3 truncate"
          style={{ width: size }}
          title={attachment.fileName}
        >
          {attachment.fileName}
        </div>
      </Link>
    </div>
  )
}

interface GenericTileProps {
  attachment: Attachment
  removing: boolean
  size: string
}

type ImageTileProps = GenericTileProps

function ImageTile({
  attachment,
  removing,
  size,
}: ImageTileProps): JSX.Element {
  return (
    <div
      className="flex items-center justify-center text-center bg-gray-200"
      style={{ width: size, height: size }}
    >
      <img
        alt={attachment.fileName}
        className={`transition ease-in-out duration-500 ${
          removing ? "opacity-25" : "opacity-100"
        }`}
        src={attachment.url}
        style={{
          maxHeight: size,
          maxWidth: size,
        }}
      />
    </div>
  )
}

type PDFTileProps = Omit<GenericTileProps, "removing" | "attachment">

function PDFTile({ size }: PDFTileProps): JSX.Element {
  return (
    <div
      className="flex flex-col items-center justify-center text-center text-orange-600 text-6xl"
      style={{ width: size, height: size }}
    >
      <Icon name="file-pdf" />
      <div className="text-xl mt-3">PDF</div>
    </div>
  )
}

type VideoFileProps = {
  size: string
  url: string
}
function VideoFile({ size, url }: VideoFileProps): JSX.Element {
  return (
    <div
      className="flex flex-col items-center justify-center text-center text-orange-600 text-6xl"
      style={{ width: size, height: size }}
    >
      <video src={url} controls={true}></video>
    </div>
  )
}
