import React, { useEffect } from "react"
import { useClickAway } from "react-use"
import { useActions } from "~src/store"
import { Icon, Link } from "~src/components"
import { ChecklistService } from "~src/services"
import { errorReporter } from "~src/common/lib"

type CategoryDropdownProps = {
  onCategoryClicked: (category: TemplateCategory) => void
  assignedCategory?: Category
  isOwner?: boolean
}

export function CategoryDropdown({
  onCategoryClicked,
  assignedCategory,
  isOwner,
}: CategoryDropdownProps): JSX.Element {
  const [expanded, setExpanded] = React.useState<boolean>(false)
  const [categories, setCategories] = React.useState<TemplateCategory[]>()
  const [selectedCategory, setSelectedCategory] = React.useState<
    TemplateCategory
  >()

  const {
    system: { showToastAlert },
  } = useActions()

  useEffect(() => {
    ;(async () => {
      try {
        const data = await ChecklistService.getTemplateCategories()
        if (data) {
          setCategories(data)
        }
      } catch (e) {
        errorReporter.notify(e)
        showToastAlert({
          text: "There is an error. please try again later",
          autoHide: true,
          type: "warning",
          iconVariant: "solid",
        })
      }
    })()
  }, [])

  const ref = React.useRef<HTMLDivElement>(null)

  const handleClick = () => {
    if (!isOwner) return
    setExpanded(!expanded)
  }

  useClickAway(ref, (e) => {
    if (!expanded) return
    setExpanded(false)
  })

  const handleCategoryClick = (category: TemplateCategory) => {
    setSelectedCategory(category)
    onCategoryClicked(category)
    setExpanded(false)
  }

  const onNoCategorySelect = () => {
    setSelectedCategory(null)
    onCategoryClicked(null)
    setExpanded(false)
  }

  const linkStyle =
    "block flex flex-row items-center justify-between px-4 py-2 transition duration-100 ease-in-out text-gray-800 hover:text-blue-600 hover:bg-gray-100 text-sm"

  return (
    <div
      className="flex flex-row items-center relative"
      ref={ref}
    >
      <Link onClick={handleClick}>
        <p className="text-primaryBlue cursor-pointer text-xs">
          {selectedCategory || assignedCategory ? (
            <>
              {selectedCategory?.name || assignedCategory.name}{" "}
              {isOwner && <Icon name="pencil" size="sm" />}
            </>
          ) : (
            <>
              <Icon name="plus" size="sm" className="mr-2" />
              Add Category
            </>
          )}
        </p>
      </Link>

      {expanded && (
        <div
          className="absolute shadow-lg rounded-md border border-gray-300 w-40 py-2 z-50 bg-white"
          style={{
            top: 30,
            left: 0,
          }}
        >
          <>
            <Link className={linkStyle} onClick={onNoCategorySelect}>
              No category
              {!assignedCategory && (
                <Icon className="m-0 text-primaryBlue" name="check" />
              )}
            </Link>
            {categories?.map((item: TemplateCategory) => (
              <Link
                className={linkStyle}
                key={item.id}
                onClick={() => handleCategoryClick(item)}
              >
                {item.name}
                {item?.id === assignedCategory?.id && (
                  <Icon className="m-0 sm:mr-3 text-primaryBlue" name="check" />
                )}
              </Link>
            ))}
          </>
        </div>
      )}
    </div>
  )
}
