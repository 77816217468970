import React from "react"

import { DateHelper } from "@bonsaichecklist/bonsai-utils"
import { Link, Avatar, Tooltip, Icon, IconButton } from "~src/components"
import { routes } from "~src/routes"
import { useActions, useState } from "~src/store"
import { AUTH_MODAL_ACTIONS, MODAL_TYPES } from "~src/common/constants"
import { useShowAuthModals } from "~src/hooks/useShowAuthModals"

export function MetaData({
  checklist,
  classNames,
  isListView,
}: {
  checklist: Checklist
  classNames?: string
  isListView?: boolean
}): JSX.Element {
  const {
    auth: { user, action, state },
  } = useState()

  const {
    system: { showToastAlert },
    checklist: { unStarChecklist, starChecklist },
    auth: { clearModalAction },
  } = useActions()

  const { id, updatedAt, createdAt, followers } = checklist

  const { handleModal } = useShowAuthModals()

  const [isStarred, setStarred] = React.useState(false)

  React.useEffect(() => {
    if (action?.key === "star") {
      clearModalAction()

      handleChecklistFavourite()
    }
  }, [state, action?.key])

  React.useEffect(() => {
    const starred = user?.starredChecklists?.some(
      (starredChecklist) => starredChecklist.starredChecklist === id
    )

    setStarred(starred)
  }, [id, user?.starredChecklists, user?.starredChecklists?.length])

  const count = followers?.length || 0
  const timeStamp = updatedAt || createdAt

  async function handleChecklistFavourite(): Promise<void> {
    if (!user) {
      handleModal(MODAL_TYPES.login, {
        key: "star",
        description: AUTH_MODAL_ACTIONS.star,
      })
      return
    }

    if (isStarred) {
      const result = await unStarChecklist(checklist)

      if (!result) {
        showToastAlert({
          text:
            "There was error in unstaring the checklist, please try again sometime later.",
          autoHide: true,
          type: "danger",
          iconVariant: "solid",
        })
      }
      return
    }

    const result = await starChecklist(checklist)

    if (!result) {
      showToastAlert({
        text:
          "There was error in starring the checklist, please try again sometime later.",
        autoHide: true,
        type: "danger",
        iconVariant: "solid",
      })
    }
  }

  return (
    <div
      className={`bg-white py-2 flex items-center justify-between pl-4 pr-2  ${classNames}`}
    >
      <div
        className={`flex items-center space-x-4 ${
          isListView && "lg:space-x-9 px-2"
        }`}
      >
        <Link
          className={`flex items-center text-xs text-gray2 ${
            isListView && "lg:w-36"
          }`}
          href={routes.users.profile(checklist?.owner?.username)}
          variant="muted"
        >
          <Avatar
            avatarUrl={checklist?.owner?.avatar?.smallMedium?.url}
            className="mr-2"
            size={16}
          />
          {checklist?.owner?.username}
        </Link>

        {isListView ? (
          <>
            <Tooltip
              tooltipTitle={`${
                count > 0 ? count : "No"
              } subscribers to this template`}
            >
              <span aria-label="Subscriber Count">
                <Icon
                  className="mr-1 sm:mr-2 text-gray3"
                  name={"star"}
                  size={"sm"}
                />{" "}
                <span className="text-gray2 text-xs font-normal">
                  {" "}
                  {count}{" "}
                </span>
              </span>
            </Tooltip>
            <Tooltip
              tooltipTitle={`${
                checklist &&
                ((checklist as Checklist).updatedAt ? "Updated" : "Created")
              } at ${DateHelper.localeDateWithMinutes(timeStamp)}`}
            >
              <p className="cursor-info inline">
                <Icon
                  className="mr-1 sm:mr-2 text-gray3"
                  name="clock"
                  size="sm"
                />
                <span className=" text-gray2 text-xs font-normal">
                  {DateHelper.fromNow(timeStamp)}
                </span>
              </p>
            </Tooltip>
          </>
        ) : (
          <>
            <Tooltip
              tooltipTitle={`${
                checklist &&
                ((checklist as Checklist).updatedAt ? "Updated" : "Created")
              } at ${DateHelper.localeDateWithMinutes(timeStamp)}`}
            >
              <p className="cursor-info inline">
                <Icon
                  className="mr-1 sm:mr-2 text-gray3"
                  name="clock"
                  size="sm"
                />
                <span className=" text-gray2 text-xs font-normal">
                  {DateHelper.fromNow(timeStamp)}
                </span>
              </p>
            </Tooltip>

            <Tooltip
              tooltipTitle={`${
                count > 0 ? count : "No"
              } subscribers to this template`}
            >
              <span aria-label="Subscriber Count">
                <Icon
                  className="mr-1 sm:mr-2 text-gray3"
                  name={"bell"}
                  size={"sm"}
                />{" "}
                <span className="text-gray2 text-xs font-normal">
                  {" "}
                  {count}{" "}
                </span>
              </span>
            </Tooltip>
          </>
        )}
      </div>

      <IconButton
        className={`blue-background-important h-8 w-8`}
        icon={"star"}
        iconVariant={isStarred ? "solid" : "light"}
        onClick={handleChecklistFavourite}
        variant={"secondary"}
      />
    </div>
  )
}
