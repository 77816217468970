import React, { useEffect, useMemo } from "react"
import { DateHelper } from "@bonsaichecklist/bonsai-utils"
import cs from "classnames"
// local imports
import {
  Alert,
  Avatar,
  CategoryDropdown,
  Icon,
  Link,
  TemplateCover,
  ToggleSwitch,
  Tooltip,
} from "~src/components"
import { routes } from "~src/routes"
import { ChecklistHelper, ScheduleHelper, errorReporter } from "~src/common/lib"
import { ChecklistService, ScheduleService } from "~src/services"
import { useRouter } from "next/router"
import { useActions, useState, useStore } from "~src/store"
import dayjs from "dayjs"
import cross from "~public/icons/cross.svg"

export interface TimelineMetaDataProps {
  checklist?: Checklist
  setHideCompletedItems?: (value: boolean) => void
}

export function TimelineMetaData({
  checklist,
  setHideCompletedItems,
}: TimelineMetaDataProps): JSX.Element {
  const {
    auth: { user },
    checklist: { checklistVerified, visibilityChanged },
    system: { featuredChecklists },
  } = useState()

  const {
    checklist: { setVerified, changeVisibility, resetChangeVisibility },
    system: { showToastAlert, changeFeaturedChecklistsCounter },
  } = useActions()

  const router = useRouter()

  const [hideItems, setHideItems] = React.useState(false)
  const latestUpdate = DateHelper.fromNow(checklist?.updatedAt)
  const handleHideShowItems = () => {
    setHideItems(!hideItems)
    setHideCompletedItems(!hideItems)
  }
  const [scheduleInfo, setScheudleInfo] = React.useState<
    ScheduleInfo | Record<string, any>
  >()
  const [isFeatured, setIsFeatured] = React.useState(checklist?.isFeatured)
  const [featureThresholdMsg, setFeatureThresholdMsg] = React.useState<boolean>(
    false
  )

  useEffect(() => {
    resetChangeVisibility()
  }, [resetChangeVisibility])

  useEffect(() => {
    if (featuredChecklists < 3) setFeatureThresholdMsg(false)
  }, [featuredChecklists])

  useEffect(() => {
    const fetchData = async () => {
      if (router) {
        const slug = router?.query?.slug as string

        const incomingSchedInfo = await ScheduleService.findInfo(slug)

        setScheudleInfo(incomingSchedInfo)
        return
      }
    }
    fetchData().catch((err) => errorReporter.notify(err))
  }, [router])

  const notifications = ScheduleHelper.getFormatedNotifications(
    scheduleInfo?.notifications
  ).match(/\d+/g)
  const notifText = ScheduleHelper.getFormatedNotifications(
    scheduleInfo?.notifications
  )
    .match(/[a-zA-Z]+/g)
    .join(" ")

  const RemovedFromCommunity = visibilityChanged
    ? Boolean(visibilityChanged?.removedFromCommunityAt)
    : Boolean(checklist?.removedFromCommunityAt)

  async function handleVerifiedChange() {
    setVerified(!checklistVerified)
    if (checklistVerified) setIsFeatured(false)

    try {
      await ChecklistService.markChecklistVerified(
        checklist?.slug,
        !checklistVerified
      )
    } catch (error) {
      showToastAlert({
        text: "Error",
        autoHide: true,
        type: "warning",
        iconVariant: "solid",
      })
      setVerified(!checklistVerified)
    }
  }

  async function handleFeaturedChecklist() {
    if (!checklistVerified) return

    const featuredChklStatus = !isFeatured
    if (featuredChklStatus && featuredChecklists >= 3) {
      setFeatureThresholdMsg(true)
      return
    }

    setIsFeatured(featuredChklStatus)
    setFeatureThresholdMsg(false)
    changeFeaturedChecklistsCounter(featuredChklStatus)

    try {
      const result = await ChecklistService.toggleFeatureChecklist(
        checklist?.slug,
        featuredChklStatus
      )

      if (!result) {
        setIsFeatured(false)
        setFeatureThresholdMsg(true)
      }
    } catch (error) {
      setIsFeatured(!featuredChklStatus)
      showToastAlert({
        text: error.message,
        autoHide: true,
        type: "warning",
        iconVariant: "solid",
      })
    }
  }

  function handleChangeVisibility() {
    const text =
      "Heads up, \n\nThis will remove the template from Community. The user will not be notified of this. Don’t forget to send them an email explaining the reason why the template has been removed."
    if (!RemovedFromCommunity) {
      if (window.confirm(text) === true) {
        changeVisibility(checklist.slug)
        setVerified(false)
        setIsFeatured(false)
        return
      }
    }
    if (RemovedFromCommunity) {
      changeVisibility(checklist.slug)
    }
  }

  const explorePageLink = (
    <Link className="text-xs no-underline" href={routes.generic.community()}>
      Explore page
    </Link>
  )

  const helper = useMemo(() => new ChecklistHelper(checklist), [checklist])
  const isOwner = helper.isOwner(user)
  const isSubscriber = helper.isSubscribed(user)

  const isAdmin = user?.role === "admin"
  const [categoryAdded, setCategoryAdded] = React.useState<Category>(null)
  const attemptToAssignCategory = React.useCallback(
    async (category?: TemplateCategory) => {
      try {
        await ChecklistService.assignCategoryToTemplate(
          checklist.slug,
          category
        ).finally(() => {
          setCategoryAdded(category || null)
        })

        showToastAlert({
          text: "Category Changed",
          autoHide: true,
          type: "success",
          iconVariant: "solid",
        })
      } catch (error) {
        showToastAlert({
          text: error.message,
          autoHide: true,
          type: "warning",
          iconVariant: "solid",
        })
      }
    },
    [checklist]
  )

  useEffect(() => {
    if (checklist?.category) {
      setCategoryAdded(checklist?.category)
    }
  }, [checklist])

  return (
    <div className="gap-y-4 grid-cols-1 grid">
      {/** about section */}
      <section
        className={cs(
          "border",
          "border-gray-300 ",
          "rounded-b-md",
          {
            "rounded-t-md": !checklist?.coverUrl,
          },

          "text-xs",
          "text-gray-600"
        )}
      >
        <TemplateCover
          chklSlug={checklist?.slug}
          coverUrl={checklist?.coverUrl}
          isOwner={isOwner}
        />

        <div className="p-6">
          <div className="text-sm font-bold text-gray-900 m-1">
            <h3>About this checklist</h3>
          </div>
          <div className="ml-1 mt-2">
            {parseInt(notifications[0]) > 0 && (
              <div className="flex flex-row gap-2 mb-2">
                <div>
                  <Icon className="text-blue-400" name="bell" variant="light" />
                </div>
                <div>
                  <p>
                    This list will notify you about{" "}
                    <strong>{notifications[0]}</strong>{" "}
                  </p>{" "}
                  {notifText}
                </div>
              </div>
            )}

            <div className="flex flex-row gap-2">
              <div>
                <Icon className="text-blue-400" name="clock" variant="light" />
              </div>
              <div>
                <p> Last update: {latestUpdate}</p>
              </div>
            </div>
            {(categoryAdded || isOwner || isAdmin) && (
              <div className="flex flex-row gap-2 items-center">
                <div>
                  <Icon
                    className="text-blue-400 mt-1"
                    name="tag"
                    variant="light"
                  />
                </div>
                {categoryAdded && <p>Category:</p>}
                <div>
                  <CategoryDropdown
                    assignedCategory={categoryAdded}
                    isOwner={isOwner || isAdmin}
                    onCategoryClicked={attemptToAssignCategory}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      {/** creator info section */}
      <section className="border border-gray-300 rounded-md text-xs text-gray-600 p-5">
        <div className="text-sm font-bold text-gray-900">
          <h3>Checklist creator</h3>
        </div>
        <div className="p-2 mt-2 max-w-sm mx-auto rounded-xl flex items-center space-x-4">
          <div className="shrink-0">
            <Avatar
              avatarUrl={checklist?.owner?.avatar?.smallMedium?.url}
              className="h-12 w-12"
              size={32}
            />
          </div>
          <div>
            <Link
              className="flex items-center text-sm font-bold"
              href={routes.users.profile(checklist?.owner?.username)}
              variant="normal"
            >
              <h3>{checklist?.owner?.username}</h3>
            </Link>
            {/* <p className="text-customGray">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim, rem
              asperiores facilis a pariatur impedit.
            </p> */}
          </div>
        </div>
      </section>

      {/** hide checklist section */}
      <section className="border border-gray-300 rounded-md text-xs text-gray-600 p-5">
        <div className="flex flex-row gap-2">
          <div>
            <Icon
              className="text-blue-400"
              name={hideItems ? "eye" : "eye-slash"}
              variant="light"
            />
          </div>
          <div>
            <Link
              className="flex items-center text-xs"
              href="/"
              onClick={handleHideShowItems}
              variant="normal"
            >
              {hideItems ? (
                <p>Show completed checklist items</p>
              ) : (
                <p>Hide completed checklist items</p>
              )}
            </Link>
          </div>
        </div>
      </section>
      {(isSubscriber || isOwner) && (
        <section className="border border-gray-300 rounded-md text-xs text-gray-600 px-2">
          <table>
            <tbody>
              <>
                <tr>
                  <td className="px-2 pt-4 font-normal text-xs text-gray-500">
                    Notification Preferences
                  </td>
                </tr>
                <tr>
                  <td className="p-2">
                    <label
                      className="form-check-label"
                      htmlFor="markVerifiedCheckbox"
                    >
                      Email Notifications
                    </label>
                  </td>
                  <td className="form-check form-switch p-2">
                    <DisableEmailNotification checklist={checklist} />
                  </td>
                </tr>
              </>
            </tbody>
          </table>
        </section>
      )}

      {/** Checklist Admin Section */}
      {user?.role === "admin" && (
        <section className="border border-gray-300 rounded-md text-xs text-gray-600 px-2">
          <table>
            <tbody>
              <>
                <tr>
                  <td className="px-2 pt-4 font-normal text-2xs text-gray-500">
                    ADMIN OPTIONS
                  </td>
                </tr>
                <tr>
                  <td className="p-2">
                    <label
                      className="form-check-label"
                      htmlFor="markVerifiedCheckbox"
                    >
                      Mark as Verified
                    </label>
                  </td>
                  <td className="form-check form-switch p-2">
                    <ToggleSwitch
                      checked={checklistVerified}
                      onClick={handleVerifiedChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="p-2">
                    <label
                      className="form-check-label"
                      htmlFor="markVerifiedCheckbox"
                    >
                      Feature template
                    </label>
                  </td>
                  <td className="form-check form-switch p-2">
                    <ToggleSwitch
                      checked={isFeatured}
                      isChecklistVerified={checklistVerified}
                      onClick={handleFeaturedChecklist}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="p-2">Shared to Community</td>
                  {!RemovedFromCommunity && (
                    <td
                      className="p-2 text-blue-500 cursor-pointer"
                      onClick={handleChangeVisibility}
                    >
                      Remove
                    </td>
                  )}
                  {RemovedFromCommunity && (
                    <div className="p-2">
                      <td className="px-1">
                        <img alt="Cross Icon" src={cross} />
                      </td>
                      <td>
                        {`Removed on ${dayjs(
                          checklist?.removedFromCommunityAt ||
                            visibilityChanged?.removedFromCommunityAt
                        ).format("MMMM Do YYYY")}`}
                      </td>
                    </div>
                  )}
                </tr>
                {RemovedFromCommunity && (
                  <tr>
                    <td className="px-2"></td>
                    <td
                      className="px-2 text-blue-500 cursor-pointer"
                      onClick={handleChangeVisibility}
                    >
                      Re-add to Community
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="p-2">Creator email</td>
                  <td className="p-2">
                    <Link
                      className="text-xs"
                      href={`mailto:${checklist?.owner?.email}`}
                    >
                      {checklist?.owner?.email}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    {featureThresholdMsg && (
                      <Alert
                        autoHide
                        className="mt-2"
                        icon="exclamation-triangle"
                        onHide={() => setFeatureThresholdMsg(false)}
                        type="warning"
                      >
                        <div className={`flex items-center pl-3`} role="alert">
                          <p className="text-xs">
                            There are already 3 featured templates on the &nbsp;
                            {explorePageLink}. Unfeature one of them to feature
                            this template.
                          </p>
                        </div>
                      </Alert>
                    )}
                  </td>
                </tr>
              </>
            </tbody>
          </table>
        </section>
      )}
    </div>
  )
}

const DisableEmailNotification = ({
  checklist,
}: {
  checklist: Checklist
}): JSX.Element => {
  const {
    actions: {
      checklist: { toggleEmailNotification },
    },
    state: {
      auth: { user },
    },
  } = useStore()
  const [isChecked, setIsChecked] = React.useState<boolean>(false)

  const handleToggleEmailNotification = React.useCallback(
    async (checked: boolean) => {
      const payload: { enable: boolean } = {
        enable: checked,
      }
      await toggleEmailNotification({
        checklist,
        payload,
      })
    },
    [checklist]
  )

  const handleChange = React.useCallback(() => {
    setIsChecked(!isChecked)
    handleToggleEmailNotification(!isChecked)
  }, [handleToggleEmailNotification, isChecked])

  React.useEffect(() => {
    if (checklist) {
      let userIds = checklist?.disabledNotificationsFor || []
      const userIndx = userIds?.findIndex((id) => id === user.id)
      setIsChecked(userIndx === -1)
    }
  }, [checklist, user])

  return (
    <Tooltip
      tooltipTitle={`${isChecked ? "Disable" : "Enable"} email notifications`}
    >
      <span>
        <ToggleSwitch checked={isChecked} onClick={handleChange} />
      </span>
    </Tooltip>
  )
}
