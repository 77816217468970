import * as React from "react"
import { useClickAway } from "react-use"

import { Icon } from "~src/components"
import edit from "~public/icons/edit.svg"
import deleteIcon from "~public/icons/Vectordelete.svg"
import eyeOff from "~public/icons/eyeoff.svg"
import eye from "~public/icons/eye.svg"
import { useState } from "~src/store"
import { useMobileDetect } from "../useMobileDetect"

interface commentOptionProps {
  handleSetEdit: () => void
  isTemplateOwner?: boolean
  isCommentOwner?: boolean
  handleDeleteComment?: () => void
  hidden?: boolean
  handleCommentVisibility?: () => void
}

export function CommentOptions({
  handleSetEdit,
  isTemplateOwner,
  isCommentOwner,
  handleDeleteComment,
  hidden,
  handleCommentVisibility,
}: commentOptionProps): JSX.Element {
  const {
    checklist: { editMode },
    auth: { user },
  } = useState()

  const isMobile = useMobileDetect()

  const ref = React.useRef<HTMLDivElement>(null)
  const [expanded, setExpanded] = React.useState<boolean>(false)

  useClickAway(ref, (e) => {
    if (!expanded) return
    setExpanded(false)
  })

  return (
    <div className="relative" ref={ref}>
      <div
        className="group cursor-pointer px-1"
        onClick={() => setExpanded((prev) => !prev)}
      >
        {(isTemplateOwner || isCommentOwner) && user && (
          <Icon
            className="text-gray-500 group-hover:text-blue-500"
            name="ellipsis-v"
            size="lg"
            variant="light"
          />
        )}
      </div>
      {expanded && (
        <div
          className={`bg-white shadow-md rounded-md absolute  w-44 ${
            isMobile ? `top-6 right-0 z-20` : `top-0 left-4`
          } `}
        >
          {isCommentOwner && (
            <>
              {!editMode && (
                <div
                  className="flex items-center  border-b border-gray-300 py-3 cursor-pointer"
                  onClick={handleSetEdit}
                >
                  <div className="w-12 flex justify-center">
                    <img alt="edit" src={edit} />
                  </div>
                  <span className="font-normal text-xs text-black hover:text-blue-500 ">
                    Edit comment
                  </span>
                </div>
              )}

              <div
                className="flex items-center  py-3  cursor-pointer"
                onClick={handleDeleteComment}
              >
                <div className="w-12 flex justify-center">
                  <img alt="delete" src={deleteIcon} />
                </div>
                <span className="font-normal text-xs text-black hover:text-blue-500">
                  Delete comment
                </span>
              </div>
            </>
          )}
          {isTemplateOwner && !isCommentOwner && (
            <>
              {!hidden && (
                <div
                  className="flex items-center  py-3  cursor-pointer"
                  onClick={handleCommentVisibility}
                >
                  <div className="w-12 flex justify-center">
                    <img alt="delete" src={eyeOff} />
                  </div>
                  <span className="font-normal text-xs text-black hover:text-blue-500">
                    Hide comment
                  </span>
                </div>
              )}
              {hidden && (
                <div
                  className="flex items-center  py-3  cursor-pointer"
                  onClick={handleCommentVisibility}
                >
                  <div className="w-12 flex justify-center">
                    <img alt="delete" src={eye} />
                  </div>
                  <span className="font-normal text-xs text-black hover:text-blue-500">
                    show comment
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  )
}
