import { IconName, library } from "@fortawesome/fontawesome-svg-core"
import {
  faArrowLeft,
  faArrowRight,
  faArrowsAlt,
  faAtlas,
  faBars,
  faBell,
  faBellOn,
  faBellSlash,
  faBold,
  faCalendarCheck,
  faCalendarMinus,
  faCalendarPlus,
  faCaretDown,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClipboard,
  faClipboardCheck,
  faClipboardListCheck,
  faClock,
  faCode,
  faCodeBranch,
  faComments,
  faCopy,
  faDownload,
  faEllipsisH,
  faEllipsisV,
  faExclamationTriangle,
  faFilePdf,
  faFilter,
  faGripLines,
  faGripVertical,
  faHandPaper,
  faHistory,
  faInfoCircle,
  faItalic,
  faLink,
  faList,
  faLock,
  faLockOpen,
  faLongArrowRight,
  faPaperclip,
  faPencil,
  faPencilAlt,
  faPlay,
  faPlus,
  faPlusSquare,
  faPortalEnter,
  faQrcode,
  faQuestionCircle,
  faRemoveFormat,
  faRepeat,
  faSearch,
  faSmile,
  faSmileBeam,
  faSpinner,
  faSpinnerThird,
  faStar,
  faStrikethrough,
  faSync,
  faTachometerAlt,
  faTasks,
  faTelescope,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faTrashUndo,
  faUnderline,
  faUndo,
  faUnlink,
  faUnlock,
  faUser,
  faThumbtack,
  faSparkles,
  faWandMagic,
  faWand,
  faEyeSlash,
  faEye,
  faTag,
} from "@fortawesome/pro-light-svg-icons"
import {
  faCopy as faCopyRegular,
  faPaperclip as faPaperclipRegular,
  faTrashAlt as faTrashAltRegular,
  faTrashRestore as faTrashRestoreRegular,
} from "@fortawesome/pro-regular-svg-icons"
import {
  faBell as faBellSolid,
  faStar as faStarSolid,
  faPlusCircle as faPlusCircleSolid,
  faArrowToLeft as faArrowToLeftSolid,
  faExclamationTriangle as faExclamationTriangleSolid,
  faSparkles as faSparklesSolid,
  faWandMagic as faWandMagicSolid,
  faWand as faWandSolid,
  faEyeSlash as faEyeSlashSolid,
  faEye as faEyeSolid,
} from "@fortawesome/pro-solid-svg-icons"
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome"
import React from "react"

// NOTE: Add more icons here:
library.add(
  faArrowLeft,
  faArrowRight,
  faArrowsAlt,
  faArrowToLeftSolid,
  faAtlas,
  faBars,
  faBell,
  faBellOn,
  faBellSlash,
  faBellSolid,
  faBold,
  faCalendarCheck,
  faCalendarMinus,
  faCalendarPlus,
  faCaretDown,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClipboard,
  faClipboardCheck,
  faClipboardListCheck,
  faClock,
  faComments,
  faCode,
  faCodeBranch,
  faCopy,
  faCopyRegular,
  faDownload,
  faEllipsisH,
  faEllipsisV,
  faExclamationTriangle,
  faExclamationTriangleSolid,
  faFilePdf,
  faFilter,
  faGripLines,
  faGripVertical,
  faHandPaper,
  faHistory,
  faInfoCircle,
  faItalic,
  faLink,
  faList,
  faLock,
  faLongArrowRight,
  faLockOpen,
  faTag,
  faPaperclip,
  faPaperclipRegular,
  faPencil,
  faPencilAlt,
  faPlay,
  faPlus,
  faPlusCircleSolid,
  faPlusSquare,
  faPortalEnter,
  faQrcode,
  faQuestionCircle,
  faRemoveFormat,
  faRepeat,
  faSearch,
  faSmile,
  faSmileBeam,
  faSpinner,
  faSpinnerThird,
  faStar,
  faStarSolid,
  faStrikethrough,
  faSync,
  faTachometerAlt,
  faTasks,
  faTelescope,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faTrashAltRegular,
  faTrashRestoreRegular,
  faTrashUndo,
  faUnderline,
  faUndo,
  faUnlink,
  faUnlock,
  faUser,
  faThumbtack,
  faSparkles,
  faSparklesSolid,
  faWandMagic,
  faWandMagicSolid,
  faWand,
  faWandSolid,
  faEyeSlashSolid,
  faEyeSlash,
  faEye,
  faEyeSolid
)

enum IconVariant {
  light = "fal",
  solid = "fas",
  regular = "far",
  duotone = "fad",
  brands = "fab",
}

export interface IconProps extends Omit<FontAwesomeIconProps, "icon"> {
  name: IconName
  variant?: keyof typeof IconVariant
}

export function Icon({
  name,
  variant = "light",
  ...props
}: IconProps): JSX.Element {
  return <FontAwesomeIcon icon={[IconVariant[variant], name]} {...props} />
}
