import React from "react"
import { DateHelper } from "@bonsaichecklist/bonsai-utils"
import { Button, Icon } from "~src/components"
import { routes } from "~src/routes"
import { useState } from "~src/store"
import { useRouter } from "next/router"
import { ChecklistHelper } from "~src/common/lib"

export function RunStatusCard({ run }: { run: ChecklistRun }): JSX.Element {
  const {
    auth: { user },
  } = useState()

  const [runStatus, setRunStatus] = React.useState<GenericObject>({})
  const chklSlug = run?.checklist?.slug
  const checklistHelper = new ChecklistHelper(run?.checklist)
  const chklOwner = checklistHelper?.ownerUsername

  const router = useRouter()

  React.useEffect(() => {
    if (run?.finishedAt) {
      setRunStatus({
        title: "Checklist completed",
        status: "Completed",
        date: DateHelper.localeDate(run.finishedAt),
      })
      return
    }
    if (run?.startedAt) {
      setRunStatus({
        title: "Checklist started",
        status: "Start",
        date: DateHelper.localeDate(run.startedAt),
      })
      return
    }
    setRunStatus({
      title: "Checklist not started yet",
      status: "Start",
      date: "-",
    })
  }, [run])

  return (
    <div className="border border-gray-300 rounded-md text-xs text-gray-600">
      <div className="flex justify-between py-4 px-6 border-b border-gray-300">
        <div className="text-sm font-bold text-gray-900">
          <h3>
            {runStatus.status === "Completed" && (
              <Icon className="mr-2 top-2.5" color="green" name="check" />
            )}{" "}
            {runStatus.title}
          </h3>
        </div>
      </div>
      <div className="p-6">
        <div className="flex gap-8 sm:gap-16">
          <div className="flex flex-col gap-y-6">
            {run?.finishedAt && <p>Started date</p>}
            <p>{runStatus.status} date</p>
            {!run?.finishedAt && <p>Last update</p>}
          </div>
          <div className="flex flex-col gap-y-6 text-gray-900">
            {run?.finishedAt && DateHelper.localeDate(run?.startedAt)}
            <p>{runStatus.date}</p>
            {!run?.finishedAt && (
              <p>
                {run?.updatedAt ? DateHelper.localeDate(run?.updatedAt) : "-"}
              </p>
            )}
          </div>
        </div>

        {user && run?.finishedAt && (
          <Button
            className="text-xs mt-6"
            href={`${routes.runs.runs(chklSlug, chklOwner)}?new=true`}
          >
            <Icon className="mr-2" name="play" />
            Create New Checklist
          </Button>
        )}
        {!run?.finishedAt && run?.slug && !router.asPath.includes("/runs") && (
          <Button className="text-xs mt-6" href={routes.runs.show(run.slug)}>
            <Icon className="mr-2" name="play" />
            Visit Checklist
          </Button>
        )}
      </div>
    </div>
  )
}
