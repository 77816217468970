import classnames from "classnames"
import React from "react"
import { Icon, IconProps, Link } from "~src/components"

export interface AlertProps {
  children?: React.ReactNode
  className?: string
  keepOpen?: boolean
  id?: string
  icon?: IconProps["name"]
  iconVariant?: IconProps["variant"]
  type?: AlertColor | 'ai-success'
  text?: string
  autoHide?: boolean
  delay?: number
  onHide?: (toastId: string) => void
}

export function Alert({
  className,
  children,
  type = "danger",
  keepOpen,
  id,
  icon = "hand-paper",
  text,
  autoHide,
  delay = 1500,
  onHide,
  iconVariant = "light",
  ...props
}: AlertProps): JSX.Element {
  const [hidden, setHidden] = React.useState<boolean>(false)

  if (type === "warning") icon = "exclamation-triangle"
  if (type === "info") icon = "info-circle"
  if (type === "success") icon = "check"
  if (type === "ai-success") icon = "sparkles"

  const handleHide = React.useCallback((): void => {
    setHidden(true)
    onHide && onHide(id)
  }, [setHidden, onHide, id])

  React.useEffect((): void | (() => void) => {
    if (autoHide) {
      const timeout = setTimeout(() => handleHide(), delay)
      return (): void => clearTimeout(timeout)
    }
  }, [handleHide, autoHide, delay])

  if (hidden) return <></>

  const classes = classnames(
    `flex flex-row py-2 rounded`,
    {
      "border-l-4 border-red-400": type === "danger",
      "border-l-4 border-yellow-400": type === "warning",
      "border-l-4 border-green-500": type === "success",
    },
    className
  )

  return (
    <div className={classes}>
      <div
        className={`w-full flex mx-2 sm:mx-5 items-center`}
        role="alert"
        {...props}
      >
        <Icon className="alert--icon mr-5 text-green-400" name={icon} variant={iconVariant} />
        <p>{text && <>{text}</>}</p>
        {children}
        {!keepOpen && (
          <Link className="ml-auto" onClick={handleHide} unstyled>
            <Icon className="ml-4" name="times" />
          </Link>
        )}
      </div>
    </div>
  )
}
