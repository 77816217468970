import { ChecklistItemsHelper, DateHelper } from "@bonsaichecklist/bonsai-utils"
import React from "react"
import { ChecklistHelper } from "~src/common/lib"

import {
  SearchProvider,
  SearchBox,
  IconButton,
  Link,
  Button,
  ChecklistPreview,
} from "~src/components"
import { ChecklistService } from "~src/services"
import { useStore } from "~src/store"
import { checklistTemplateContext } from "~src/contextStores"

type Props = {
  setIsCopyingItems: (value: boolean) => void
}

export function SideBySide({ setIsCopyingItems }: Props): JSX.Element {
  const {
    state: {
      auth: { user },
    },
    actions: {
      checklist: { copyItems },
      system: { setSideBySide },
    },
  } = useStore()

  const [showSearch, setShowSearch] = React.useState<boolean>(true)
  const [secondChecklistData, setSecondChecklistData] = React.useState<{
    secondChecklist: Checklist
    itemMap?: ChecklistItemMap
    title?: string
    visibility?: string
    lastUpdated?: Date
    ownerName?: string
    isOwnedByCurrentUser?: boolean
    rootItems?: ChecklistItem[]
    scheduleMap?: ChecklistScheduleMap
  }>()

  const isSideBySideCopy = true

  const handleSearchClick = async (slug: string) => {
    const secondChecklist = await ChecklistService.findBySlug(slug)

    if (!secondChecklist) return

    const helper = new ChecklistHelper(secondChecklist)
    const isOwner = helper.isOwner(user)

    const rootItems = secondChecklist.items
      .filter((i) => !i.parent)
      .sort(ChecklistItemsHelper.sortChecklistItemsByOrder)
    const scheduleMap = secondChecklist?.schedules?.reduce(
      (all, scheduledItem) => {
        all[scheduledItem.itemSlug] = scheduledItem

        return all
      },
      {} as ChecklistScheduleMap
    )
    setSecondChecklistData({
      secondChecklist,
      itemMap: ChecklistItemsHelper.makeItemMap(secondChecklist.items),
      isOwnedByCurrentUser: isOwner,
      rootItems,
      scheduleMap,
      ownerName: secondChecklist.owner.username,
      lastUpdated: secondChecklist.updatedAt,
      title: secondChecklist.title,
      visibility: secondChecklist.visibility,
    })
  }

  const copyItem = (
    item: ChecklistItem,
    sourceChecklist: Checklist,
    subItems?: ChecklistItem[],
    itemMap?: ChecklistItemMap
  ) => {
    copyItems({
      item,
      copiedFromChecklist: sourceChecklist,
      subItems,
      refItemMap: itemMap,
      scheduleMap: secondChecklistData.scheduleMap,
    })
  }

  const resetSecondChecklistData = () => {
    setSecondChecklistData({
      secondChecklist: undefined,
      itemMap: undefined,
      title: undefined,
      visibility: undefined,
      lastUpdated: undefined,
      ownerName: undefined,
      isOwnedByCurrentUser: undefined,
      rootItems: undefined,
    })
  }

  return (
    <aside className="pl-6 border-l-2 border-solid border-blueGray-200 mt-8">
      <div>
        <p className="mb-4 font-semibold">Side by Side</p>
        {showSearch && (
          <SearchProvider>
            <div className="flex flex-1">
              <SearchBox
                disableOuterScroll={!isSideBySideCopy}
                handleOnClick={handleSearchClick}
                onClose={() => setShowSearch(false)}
                showSearchClose={false}
              />
            </div>
          </SearchProvider>
        )}
        {!showSearch && (
          <IconButton
            icon="search"
            onClick={() => setShowSearch(true)}
            variant="link"
          />
        )}
      </div>
      <hr className="my-8 border-gray-200" />
      {secondChecklistData && secondChecklistData.secondChecklist && (
        <div className="overflow-y-scroll mt-4" style={{ maxHeight: 712 }}>
          <div className="mb-8">
            <h1 className="text-2xl font-medium">
              {secondChecklistData?.title}
            </h1>
            <div className="flex text-gray-600 mt-2">
              <p className="capitalize-first">
                {secondChecklistData?.visibility}
              </p>
              <span className="mx-2">•</span>
              <p>
                Updated {DateHelper.fromNow(secondChecklistData?.lastUpdated)}
              </p>
              <span className="mx-2">•</span>
              <Link href="/">@{secondChecklistData?.ownerName}</Link>
            </div>
          </div>
          <checklistTemplateContext.Provider
            value={{
              checklist: secondChecklistData?.secondChecklist,
              isOwner: secondChecklistData?.isOwnedByCurrentUser,
              rootItems: secondChecklistData?.rootItems,
            }}
          >
            <div style={{ marginTop: "6.6rem" }}>
              <ChecklistPreview onAdd={copyItem} showCheckBox={false} />
            </div>
          </checklistTemplateContext.Provider>
        </div>
      )}
      <Button
        className="ml-auto mt-8 sm:visible"
        onClick={() => {
          setIsCopyingItems(false)
          setSideBySide(false)
          resetSecondChecklistData()
        }}
      >
        Done
      </Button>
    </aside>
  )
}
