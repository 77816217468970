import { Icon } from "../Icon"
import { Link } from "../Link"
import magicWandIcon from "~public/icons/magic-wand.svg"


export interface AddAiGeneratedItemsProps{
  handleGenerateItems: () => void
    loading: boolean
}
function AddAiGeneratedItems({
    handleGenerateItems,
    loading,
  }: AddAiGeneratedItemsProps): JSX.Element {
    return (
      <Link
        className="cursor-pointer align-middle"
        disabled={loading}
        onClick={handleGenerateItems}
      >
        <div className="flex gap-2">
          {loading ? (
            <Icon className="text-md" name="sparkles" spin variant="solid" />
          ) : (
            <img alt="copy-icon" className="h-4" src={magicWandIcon} />
          )}
          <small>Generate 3 more items</small>
        </div>
      </Link>
    )
  }
  export default AddAiGeneratedItems