import classnames from "classnames"
import dayjs from "dayjs"
import React from "react"
import {
  formatLinkedListName,
  ScheduleHelper,
  sortChecklistItems,
  sortRunItems,
} from "~src/common/lib"
import {
  Button,
  ChecklistItemBody,
  Icon,
  IconButton,
  ScheduleCardEllipsisdropdown,
} from "~src/components"
import { ScheduleService } from "~src/services/ScheduleService"
import { DateHelper, MarkdownHelper } from "@bonsaichecklist/bonsai-utils"
import elipseBlue from "~src/../public/icons/elipse-empty-blue.svg"
import { Link } from "../Link"

export interface ScheduleItemProps {
  checklist: Checklist
  chklItemMap?: ChecklistItemMap
  onDelete?: (id: string) => void
  schedule: Schedule
  showActions?: boolean
  showItems?: boolean
  showDivider?: boolean
}

export function ScheduleItem({
  checklist,
  chklItemMap,
  onDelete,
  schedule,
  showActions = true,
  showItems = true,
}: ScheduleItemProps): JSX.Element {
  const [showUpcoming, setShowUpcoming] = React.useState<boolean>(false)
  const [trashing, setTrashing] = React.useState<boolean>(false)
  const [trashed, setTrashed] = React.useState<boolean>(false)
  const [showTrash, setShowTrash] = React.useState<boolean>(false)
  const [cancelledRuns, setCancelledRuns] = React.useState<CanceledRun[]>([])
  // Take our local list of cancelled runs and combine them with the stored list
  // of cancelled runs so that we can ensure that the list of upcoming occurrences
  // is always accurate. This isn't the cleanest way to do it, instead we should
  // likely just re-fetch the schedule, but for now this should work reasonably well.
  schedule.cancelledOccurrences = schedule.cancelledOccurrences?.length
    ? [...schedule.cancelledOccurrences, ...cancelledRuns]
    : cancelledRuns
  const helper = new ScheduleHelper(schedule)
  const upcoming = helper.upcoming()

  //  console.log(schedule,"schedule list;")
  /**
   * When cancelling all occurrences, let's also trash the
   * schedule so the API doesn't return it anymore.
   */
  async function handleCancelAll(): Promise<void> {
    setTrashing(true)
    await ScheduleService.cancelSchedule(checklist.slug, schedule.id)

    onDelete(schedule.id)

    setShowTrash(false)
    setTrashing(false)
    setTrashed(true)
  }

  function handleCancelSingle(date: string): void {
    setCancelledRuns([
      ...cancelledRuns,
      { cancelledAt: new Date(), cancelledOccurrence: dayjs(date).toDate() },
    ])
  }
  React.useEffect(() => {
    if (!upcoming.length && typeof onDelete === "function")
      onDelete(helper.schedule.id)
  }, [helper.schedule.id, onDelete, upcoming])

  if (trashed || !upcoming?.length) return null

  function cleanItemBody(item: ChecklistItem) {
    return item?.linkedList
      ? formatLinkedListName(item).substring(0, 50)
      : new MarkdownHelper(item?.body || "").text
  }

  const container = classnames(
    { "opacity-50": trashing },
    "block bg-white border border-gray-200 rounded-lg shadow-md md:w-scheduleCardWidth"
  )

  const sortedChecklistItems = sortChecklistItems(chklItemMap)

  const itemsToSchedule = sortRunItems(
    schedule.itemsToSchedule,
    sortedChecklistItems
  )

  return (
    <div className={"flex flex-row mt-6"}>
      <div className={container}>
        <div className={"p-6"}>
          <div className="flex items-center justify-between">
            <div className={"flex flex-row items-center"}>
              <h3 className="text-base font-bold capitalize-first">
                <ChecklistItemBody
                  item={itemsToSchedule[0]}
                ></ChecklistItemBody>
              </h3>
            </div>
            <div className="ml-2 ">
              {showTrash ? (
                <div className="flex items-center">
                  <Button destructive onClick={handleCancelAll} size="sm">
                    Delete this schedule
                  </Button>
                  <IconButton
                    className="ml-2"
                    icon="times"
                    onClick={(): void => setShowTrash(false)}
                    size="sm"
                    variant="link"
                  />
                </div>
              ) : (
                showActions && (
                  <ScheduleCardEllipsisdropdown onDelete={handleCancelAll} />
                )
              )}
            </div>
          </div>
          <p className="text-gray-600 text-sm mt-2">
            <Icon className="mr-2 text-blue-500" name="clock" />
            {helper.friendlyDescription}
          </p>

          {showItems && schedule.runType === "item" && (
            <div className="ml-8 my-2">
              {itemsToSchedule.map((item, index) => (
                <li className="text-sm" key={index}>
                  {cleanItemBody(item)}
                </li>
              ))}
            </div>
          )}
        </div>

        {schedule.recurrencePattern !== "single-occurrence" && (
          <div className="flex flex-col sm:flex-row items-start sm:items-center mt-2 text-xs text-gray-600 bg-gray-100 p-3 ">
            <span className="mr-2 py-1 flex flex-row ml-3 items-center">
              <Icon className="mr-2 text-blue-500" name="play" />
              Next run: {DateHelper.localeDate(upcoming[0])}
            </span>

            <Link
              className="sm:ml-4"
              onClick={(): void => setShowUpcoming(!showUpcoming)}
            >
              <span className="text-xs">
                {showUpcoming ? "Hide dates" : "Show more dates"}
              </span>
            </Link>
          </div>
        )}

        {showUpcoming && (
          <div
            className="overflow-auto h-48 bg-gray-100 px-6 py-4 rounded mt-4"
          >
            {upcoming.map((run) => (
              <Occurrence
                checklist={checklist}
                key={run.getTime()}
                onCancel={handleCancelSingle}
                run={run}
                schedule={schedule}
                showActions={showActions}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

function Occurrence({
  checklist,
  run,
  onCancel,
  schedule,
  showActions,
}: {
  checklist: Checklist
  run: Date
  onCancel: (date: string) => void
  schedule: Schedule
  showActions?: boolean
}): JSX.Element {
  const [trashing, setTrashing] = React.useState<boolean>(false)
  const [trashed, setTrashed] = React.useState<boolean>(false)
  const [showTrash, setShowTrash] = React.useState<boolean>(false)
  async function handleCancelSingle(): Promise<void> {
    setTrashing(true)
    await ScheduleService.cancelOccurrence(checklist.slug, schedule.id, run)
    setShowTrash(false)
    setTrashing(false)
    setTrashed(true)
    // Do this at the end so the component can finish it's
    // state updates and we don't get any memory leaks.
    onCancel(run.toISOString())
  }

  if (trashed) return <></>

  const container = classnames("flex items-center my-1 text-sm", {
    "opacity-50": trashing,
  })

  const timestamp = run.getTime()
  return (
    <div className={container}>
      {DateHelper.localeDate(run)}

      {showActions && (
        <div className="ml-2">
          {showTrash ? (
            <div className="flex items-center">
              <IconButton
                className="mr-2"
                icon="times"
                onClick={(): void => setShowTrash(false)}
                size="sm"
                variant="link"
              />
              <Button
                destructive
                onClick={handleCancelSingle}
                size="sm"
              >
                Cancel this run
              </Button>
            </div>
          ) : (
            <IconButton
              destructive
              icon="trash-alt"
              onClick={(): void => setShowTrash(true)}
              size="sm"
              title="Cancel run"
              variant="link"
            />
          )}
        </div>
      )}
    </div>
  )
}
