import classnames from "classnames"
import React from "react"
import { URLHelper } from "~src/common/lib"
import { Icon, Link } from "~src/components"
import { ToolbarProps } from "~src/components/Editor/Toolbar"
import { buttonClasses } from "~src/components/Editor/utils"

type LinkFormProps = Omit<ToolbarProps, "currentInlineStyle">

export function LinkForm(props: LinkFormProps): JSX.Element {
  const [url, setURL] = React.useState<string>("")
  const [focused, setFocused] = React.useState<boolean>(false)

  // TODO: handle escape to close

  React.useEffect(() => {
    setURL(props.currentLinkURL)
  }, [props.currentLinkURL])

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setURL(e.currentTarget.value)
  }

  function handleSubmit(e: React.FormEvent): void {
    e.preventDefault()
    e.stopPropagation()
    props.link(URLHelper.prefixURL(url))
  }

  function handleFocus(): void {
    setFocused(true)
  }

  function handleBlur(e: React.FormEvent): void {
    setFocused(false)
    if (url) {
      handleSubmit(e)
    }
  }

  function handleHideLinkEditor(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void {
    e.preventDefault()
    props.hideLinkEditor()
  }

  const containerClasses = "flex flex-row items-center block w-full"
  const inputClasses = classnames(
    "block w-full rounded bg-transparent focus:bg-gray-100 transition ease-in-out duration-200 px-3 py-2 placeholder-gray-400 focus:outline-none",
    {
      "border-blue-500 shadow-outline": focused,
    }
  )

  const btnClasses = buttonClasses()

  return (
    <form className={containerClasses} onSubmit={handleSubmit}>
      <input
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        autoFocus
        className={inputClasses}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        placeholder="https://google.com..."
        type="text"
        value={url}
      />
      <div className="ml-2">
        <Link
          className={btnClasses}
          onClick={handleHideLinkEditor}
          title="Cancel"
          unstyled
        >
          <Icon fixedWidth name="times" />
        </Link>
      </div>
    </form>
  )
}
