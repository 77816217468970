import { AnimatePresence, motion } from "framer-motion"
import React from "react"
import * as yup from "yup"
import { MODAL_TYPES, SUBBED_AS_LOCAL_KEY } from "~src/common/constants"
import {
  Button,
  Form,
  Input,
  Link,
  SetUsernameField,
  USERNAME_VALIDATION_SCHEMA,
} from "~src/components"
import { useShowAuthModals } from "~src/hooks/useShowAuthModals"
import { useStore } from "~src/store"
import { SignupFormProps } from "./signup-form.types"

const schema = yup.object().shape({
  email: yup.string().email().required(),
  username: USERNAME_VALIDATION_SCHEMA,
  password: yup.string().required(),
})

export function SignUpForm({
  onSubmit,
  passwordError,
  signingUp,
}: SignupFormProps): JSX.Element {
  const { handleModal } = useShowAuthModals()

  const [isUsernameAvailable, setIsUsernameAvailable] = React.useState(false)
  const [subbedEmail, setSubbedEmail] = React.useState()
  const {
    state: {
      auth: { error, action },
    },
    actions: {
      auth: { resetError },
    },
  } = useStore()

  React.useEffect(() => {
    return (): void => resetError()
  }, [resetError])

  function handleSubmit(values: {
    username: string
    email: string
    password: string
  }): void {
    if (isUsernameAvailable) {
      onSubmit(values)
    }
  }

  React.useEffect(() => {
    const localSubs = JSON.parse(localStorage.getItem(SUBBED_AS_LOCAL_KEY))
    if (localSubs) {
      const subscribedEmail = localSubs[0]?.email
      setSubbedEmail(subscribedEmail)
    }
  }, [])

  function handleOnFocus(): void {
    resetError()
  }

  return (
    <Form
      onSubmit={handleSubmit}
      schema={schema}
      values={{
        email: subbedEmail,
      }}
    >
      {({ values, errors, handleChange, handleSubmit }: any): any => (
        <form onSubmit={handleSubmit}>
          <AnimatePresence>
            {error && (
              <motion.div
                animate={{ opacity: 1 }}
                className="mb-2 text-red-600"
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                transition={{ duration: 0.4 }}
              >
                {error?.toString()}
              </motion.div>
            )}
          </AnimatePresence>

          <div className="my-4">
            {errors?.email && (
              <div className="mb-2 text-red-600">{errors.email}</div>
            )}
            <Input
              autoCapitalize="none"
              autoComplete="off"
              id="email"
              onChange={handleChange}
              onFocus={handleOnFocus}
              placeholder="Email address"
              type="email"
              value={values.email}
              defaultValue={values.email}
            />
          </div>
          <SetUsernameField
            error={errors?.username}
            isUsernameAvailable={isUsernameAvailable}
            onChange={handleChange}
            setIsUsernameAvailable={setIsUsernameAvailable}
            value={values.username}
          />
          <div className="my-4">
            {errors?.password && (
              <div className="mb-2 text-red-600">{errors.password}</div>
            )}
            {passwordError && (
              <div className="mb-2 text-red-600">
                Minimum password length is any combination of 8 characters of
                alphanumeric and/or special characters.
              </div>
            )}
            <Input
              autoCapitalize="none"
              autoCorrect="off"
              id="password"
              onChange={handleChange}
              placeholder="Password"
              type="password"
              value={values.password}
            />
          </div>
          <div className="my-8">
            <Button
              disabled={
                !values.email ||
                !values.username ||
                !values.password ||
                signingUp
              }
              fullWidth
              leftIcon="lock"
              onClick={handleSubmit}
              type="submit"
            >
              Create Account
            </Button>
          </div>
          <div className="mt-6">
            Already have an account?{" "}
            <Link
              onClick={() =>
                handleModal(MODAL_TYPES.login, { key: action?.key })
              }
            >
              Login
            </Link>
          </div>
        </form>
      )}
    </Form>
  )
}
