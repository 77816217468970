import React from "react"
import { Button,  IconButton, IconButtonProps } from "~src/components"

export interface ModalProps {
  children?: React.ReactNode
  config?: {
    showCloseButton?: boolean
    showCloseIcon?: boolean
    width?:
      | "xs"
      | "sm"
      | "md"
      | "lg"
      | "xl"
      | "2xl"
      | "3xl"
      | "4xl"
      | "5xl"
      | "6xl"
      | "7xl"
  }
  onClose?: () => void
  title?: string
  styles?: { width: string; padding: string }
  classNames?: { titleClassNames?: string }
  CustomTitleComponent?: JSX.Element
  copyBtnProps?: IconButtonProps
}

const defaultConfig: ModalProps["config"] = {
  showCloseButton: true,
  showCloseIcon: true,
  width: "lg",
}

const classes = {
  titleClassNames: "text-gray-900 text-2xl font-bold leading-tight",
}

export function Modal({
  children,
  onClose,
  title,
  config,
  styles,
  classNames,
  CustomTitleComponent,
  copyBtnProps,
}: ModalProps): JSX.Element {
  config = { ...defaultConfig, ...config }

  const { showCloseButton, showCloseIcon } = config

  const titleClasses = { ...classes, ...classNames }

  return (
    <div>
      <div className="fixed  inset-0 overflow-y-auto z-[999]">
        <div className="items-start  justify-center min-h-screen p-6 text-center block">
          <div
            aria-hidden="true"
            className="fixed inset-0 transition-opacity"
            onClick={onClose}
          >
            <div className="absolute inset-0 bg-gray-700 opacity-75"></div>
          </div>

          <span
            aria-hidden="true"
            className="inline-block align-middle h-screen"
          >
            &#8203;
          </span>

          <article
            aria-labelledby="modal-headline"
            aria-modal="true"
            className={`${
              styles?.width.length ? styles?.width : "w-full"
            } inline-block  bg-white rounded-md text-left
              shadow-xl transform transition-all align-middle max-w-${
                config.width
              }`}
            role="dialog"
          >
            <div className="flex justify-end pr-1 pt-1">
              {showCloseIcon && (
                <IconButton
                  className="text-xl absolute"
                  icon="times"
                  muted
                  onClick={onClose}
                  variant="link"
                />
              )}
            </div>
            <div className={`${styles?.padding ? styles?.padding : "p-6"}`}>
              <header className="flex items-center mb-4 justify-between">
                {!CustomTitleComponent ? (
                  <h2 className={titleClasses.titleClassNames}>{title}</h2>
                ) : (
                  CustomTitleComponent
                )}
                {copyBtnProps ? <IconButton className="mt-2" {...copyBtnProps} /> : <></>}
              </header>
              <div className="text-sm text-gray-700">{children}</div>
              {showCloseButton && (
                <footer className="pt-8 sm:flex sm:flex-row-reverse">
                  {showCloseButton && (
                    <Button onClick={onClose} variant="secondary">
                      Close
                    </Button>
                  )}
                </footer>
              )}
            </div>
          </article>
        </div>
      </div>
    </div>
  )
}
