import React from "react"
import { useRouter } from "next/router"
import { useClickAway } from "react-use"
import { isMobile } from "react-device-detect"
import {
  TOUR_CHECKLIST_SLUG,
  TOUR_PROD_CHECKLIST_SLUG,
  TOUR_PROD_CHECKLIST_OWNER,
  PRISMIC_PAGES,
} from "~src/common/constants"
import { Avatar, FeedbackModal, Icon, Link } from "~src/components"
import { routes } from "~src/routes"
import { useStore } from "~src/store"
import { PrismicService } from "~src/services"
import settingsGearIcon from "~src/../public/icons/gear_icon_blue.svg"
import { googleLogout } from "@react-oauth/google"

export function AccountDropdown(): JSX.Element {
  const [showFeedbackModal, setShowFeedbackModal] = React.useState<boolean>(
    false
  )
  const [expanded, setExpanded] = React.useState<boolean>(false)
  const ref = React.useRef<HTMLDivElement>(null)
  const router = useRouter()
  const {
    actions: {
      auth: { logout },
      productTour: {
        setStartFromChecklistPage,
        handleOnExitIntro,
        setFullProductTour,
      },
    },
    state: { auth },
  } = useStore()

  const { user, state } = auth

  function handleClick(): void {
    setExpanded(!expanded)
  }

  function handleShowFeedback(): void {
    setExpanded(false)
    setShowFeedbackModal(true)
  }

  async function handleLogout(): Promise<void> {
    await logout()
    googleLogout()
    router.push(routes.generic.community())
  }

  useClickAway(ref, (e) => {
    if (!expanded) return
    setExpanded(false)
  })

  async function handleManualProductTour(): Promise<void> {
    setExpanded(false)

    await handleFullProductData()

    setStartFromChecklistPage({ startManualTour: true })
    handleOnExitIntro({ page: "accountDropdown", stepIndex: 1 }) //skip advance tour

    const chklSlug = window.location.hostname.includes("staging")
      ? TOUR_CHECKLIST_SLUG
      : TOUR_PROD_CHECKLIST_SLUG

    router.push(routes.checklists.show(chklSlug, TOUR_PROD_CHECKLIST_OWNER))

    return
  }

  async function handleFullProductData(): Promise<void> {
    const { steps: basicSteps } = await PrismicService.getPageData(
      auth,
      PRISMIC_PAGES.basicTour
    )
    const { steps: advanceSteps } = await PrismicService.getPageData(
      auth,
      PRISMIC_PAGES.advanceTour
    )

    const stepsData = [...basicSteps, ...advanceSteps]

    setFullProductTour({ stepsData: stepsData })
  }

  const isAuthenticated = state === "authenticated"

  const showFeedback = isAuthenticated

  const linkStyle =
    "block flex flex-row items-center px-4 py-2 transition duration-100 ease-in-out text-gray-800 hover:text-blue-600 hover:bg-gray-100"
  const iconStyle = "mr-3 text-primaryBlue"

  return (
    <div
      className="flex flex-row items-center relative"
      ref={ref}
    >
      <Link onClick={handleClick}>
        <Avatar
          avatarUrl={user?.avatar?.medium.url}
          size={32}
          title={`@${user?.username}`}
        />
      </Link>
      {expanded && (
        <div
          className="absolute shadow-lg rounded-md border border-gray-300 w-56 py-2 z-50 bg-white"
          style={{
            top: 40,
            right: 0,
          }}
        >
          <div className="px-4 py-2 text-sm">
            <div className="text-gray-500 mb-1">Signed in as:</div>
            <div className="text-gray-700">
              <Link href={routes.users.profile(user?.username)} variant="muted">
                <strong>{user?.username}</strong>
              </Link>
            </div>
          </div>
          <Divider />
          {user?.role === "admin" && !isMobile && (
            <>
              <Link
                className={linkStyle}
                href={routes.generic.adminCategories()}
              >
                <img
                  alt={"gear_icon_blue"}
                  className={iconStyle}
                  src={settingsGearIcon}
                />
                Admin
              </Link>
              <Divider />
            </>
          )}

          {state === "authenticated" && (
            <>
              <Link
                className={linkStyle}
                href={routes.generic.yourSubscriptions()}
              >
                <Icon className={iconStyle} name="bell-on" />
                Your Subscriptions
              </Link>
              <Link className={linkStyle} href={routes.generic.yourStarred()}>
                <Icon className={iconStyle} name="star" />
                Your Stars
              </Link>
            </>
          )}
          {showFeedback && (
            <>
              <Divider />
              <Link
                className={linkStyle}
                href={"#"}
                onClick={handleShowFeedback}
              >
                <Icon className={iconStyle} name="comments" />
                Send Feedback
              </Link>
              <Divider />
            </>
          )}
          <Link
            className={linkStyle}
            onClick={handleLogout}
          >
            <Icon className={iconStyle} name="portal-enter" />
            Logout
          </Link>
        </div>
      )}
      {showFeedbackModal && (
        <FeedbackModal onClose={() => setShowFeedbackModal(false)} />
      )}
    </div>
  )
}

export function Divider(): JSX.Element {
  return <div className="border-b border-gray-200"></div>
}
