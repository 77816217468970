/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { useClickAway } from "react-use"
import { Button, Icon } from "~src/components"

export interface ScheduleCardEllipsisdropdownProps {
  onDelete: () => void
}

export function ScheduleCardEllipsisdropdown({
  onDelete,
}: ScheduleCardEllipsisdropdownProps): JSX.Element {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  const ref = React.useRef<HTMLDivElement>(null)

  function handleIsOpen() {
    setIsOpen(!isOpen)
  }

  useClickAway(ref, (e) => {
    if (!isOpen) return
    setIsOpen(false)
  })

  return (
    <div className="relative h-max-content" ref={ref}>
      <Button
        className="h-max-content w-max-content text-sm md:inline"
        onClick={handleIsOpen}
        tooltip="Quick actions"
        variant="link"
      >
        <Icon
          className={`m-auto ${isOpen || "text-gray-400"}`}
          size="2x"
          name="ellipsis-v"
        />
      </Button>

      {isOpen && (
        <div
          className="absolute shadow-lg rounded-md border border-gray-300 max-h-72 max-w-auto  overflow-y-auto  lg:w-auto  z-50 bg-white"
          style={{
            top: 32,
            left: 15,
          }}
        >
          <ul className="md:w-72 text-xs font-light text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
            <li className="py-2 px-4 w-full border-b border-gray-200 dark:border-gray-600">
              <button
                className="block py-2 px-2 md:px-4 focus:outline-none hover:bg-gray-100 dark:hover:bg-gray-600 text-blue-600 dark:hover:text-white flex flex-row items-center "
                onClick={onDelete}
              >
                <Icon className="m-0 md:mr-3 text-red-400" name="trash-alt" />
                <p className="md:inline hidden">
                Delete Scheduled Checklist
                </p>
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  )
}
