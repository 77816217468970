import React from "react"
import classnames from "classnames"
import { Icon, IconProps } from "~src/components"

export interface ExpansionCaretProps extends Omit<IconProps, "name"> {
  expanded: boolean
  unScheduledItems?: boolean
  chklCategoryItems?: boolean
}

export function ExpansionCaret({
  expanded,
  className,
  unScheduledItems,
  chklCategoryItems,
  ...props
}: ExpansionCaretProps): JSX.Element {
  const classes = classnames(
    className,
    "transition-all duration-200 transform",
    {
      "rotate-90": expanded,
      "rotate-0": !expanded,
    }
  )
  const unScheduledItemclasses = classnames(
    className,
    "transition-all duration-200 transform",
    {
      "rotate-0": expanded,
      "rotate-270": expanded && unScheduledItems,
      "rotate-90": !expanded,
    }
  )

  const chklCategoryItemsClasses = classnames(
    className,
    "transition-all duration-200 transform",
    {
      "-rotate-90": !expanded,
      "rotate-0": expanded && chklCategoryItems,
      "rotate-90": expanded,
    }
  )
  return (
    <Icon
      className={
        unScheduledItems
          ? unScheduledItemclasses
          : chklCategoryItemsClasses
          ? chklCategoryItemsClasses
          : classes
      }
      name="chevron-right"
      {...props}
    />
  )
}
