/* global google */
import { config as faConfig } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"
import Honeybadger from "@honeybadger-io/js"
import debug from "debug"
import { NextPageContext } from "next"
import App, { AppProps } from "next/app"
import Head from "next/head"
import Router from "next/router"
import {
  Config,
  createOvermind,
  createOvermindSSR,
  Overmind,
  rehydrate,
} from "overmind"
import React from "react"
import { GoogleOAuthProvider } from "@react-oauth/google"

import { isServerRendered } from "~src/common/lib"
import { Wrapper } from "~src/components"
import { config } from "~src/store"
import { AuthResponse, AuthService } from "~src/store/auth/effects"
import { getLoggedUserFromHeaders } from "~src/store/checklist/actions/utils"

import progress from "~src/plugins/Progress"

import "tippy.js/dist/tippy.css"
import "~src/styles/base.css"
import "~src/styles/tailwind.css"
import "react-circular-progressbar/dist/styles.css"

faConfig.autoAddCss = false

const d = debug("chainlist/pages/_app")

interface PageProps {
  auth?: AuthResponse
  err?: any
}

declare global {
  interface Window {
    google: any
  }
}

Honeybadger.configure({
  environment: process.env.NODE_ENV,
  apiKey: process.env.HONEYBADGER_API_KEY,
})

export default class ChainList extends App<PageProps, PageProps> {
  private overmind: Overmind<Config>

  // CLIENT: On initial route
  // SERVER: On initial route
  constructor(props: AppProps<PageProps>) {
    super(props)
    d("page props:", props)

    // const mutations = props.pageProps?.mutations || []
    const auth = props.pageProps?.auth
    if (isServerRendered()) {
      // On the server we rehydrate the mutations to an SSR instance of Overmind,
      // as we do not want to run any additional logic here
      d("running on server")
      this.overmind = createOvermindSSR(config)

      if (auth) this.overmind.actions.auth.setAuth(auth)

      if (!auth) this.overmind.actions.auth.setSearchToken()

      this.overmind.actions.auth.setCommunitySearchToken()

      rehydrate(this.overmind.state, [])
    } else {
      // On the client we just instantiate the Overmind instance
      // and set the user
      d("running on client")
      this.overmind = createOvermind(config, {
        devtools: true, // defaults to 'localhost:3031'
      })

      if (auth) this.overmind.actions.auth.setAuth(auth)

      if (!auth) this.overmind.actions.auth.setSearchToken()

      this.overmind.actions.auth.setCommunitySearchToken()
    }
  }

  handleGsiResponse: any = async (response: any) => {
    if (response?.credential) {
      const AuthResponseData = await AuthService.loginWithGoogle(
        response.credential
      )
      if (AuthResponseData && AuthResponseData?.user.id)
        this.overmind.actions.auth.setAuth(AuthResponseData)
    }
  }

  static async getInitialProps({
    ctx: { req },
  }: {
    ctx: NextPageContext
  }): Promise<{ pageProps: { auth?: AuthResponse; searchToken?: string } }> {
    try {
      const auth = await getLoggedUserFromHeaders(req)
      return { pageProps: { auth } }
    } catch {
      return { pageProps: { auth: null } }
    }
  }

  // CLIENT: On every page change
  // SERVER: On initial route
  render(): JSX.Element {
    const { Component, pageProps, err } = this.props

    Router.events.on("routeChangeStart", (url) => {
      const startLoading = progress.start()
      startLoading.settings.showSpinner = false
    })

    Router.events.on("routeChangeComplete", () => progress.done())
    Router.events.on("routeChangeError", () => progress.done())

    return (
      <Wrapper store={this.overmind}>
        <GoogleOAuthProvider
          clientId={process.env.NEXT_PUBLIC_GOOGLE_AUTH_CLIENT_ID}
        >
          <Head>
            <meta
              content="width=device-width, initial-scale=1, maximum-scale=1"
              name="viewport"
            />
            <meta charSet="utf-8" />
          </Head>
          <Component {...pageProps} err={err} />
        </GoogleOAuthProvider>
      </Wrapper>
    )
  }
}
