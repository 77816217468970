import { DateHelper } from "@bonsaichecklist/bonsai-utils"
import React, { useMemo } from "react"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"

import { useClickAway } from "react-use"
import checkGreenIcon from "~src/../public/icons/check-green.svg"
import clockWiseIcon from "~src/../public/icons/clockwise.svg"
import { routes } from "~src/routes"
import { useStore } from "~src/store"
import { Icon } from "../Icon"
import { IconButton } from "../IconButton"
import { ItemActions } from "../ItemActions"
import { Link } from "../Link"
import { QrCodeModal } from "../QrCodeModal"
import { Tooltip } from "../Tooltip"
import { useMobileDetect } from "../useMobileDetect"

type RunChecklistInfo={
  _id:string
  slug:string
  owner:{
    username:string
  }
}
interface Props {
  isRecurring?: boolean
  showProgress?: boolean
  pattern?: string
  friendlyDescription?: string
  hasAttachments?: boolean
  setShowAttachments?: (attachment: boolean) => void
  showAttachments?: boolean
  item?: any
  completedOn?: Date | string
  status?: number
  hasSubItems?: boolean
  runSlug?: string,
  checklistTitle?: string
  checklistInfo?:RunChecklistInfo
  isUpdated?: boolean
}
export function ChecklistStatus({
  isRecurring,
  showProgress,
  pattern,
  friendlyDescription,
  hasAttachments,
  setShowAttachments,
  showAttachments,
  item,
  completedOn,
  status,
  hasSubItems,
  runSlug,
  checklistTitle,
  checklistInfo,
  isUpdated,
}: Props): JSX.Element {
  const {
    state: {
      checklist: { current },
    },
  } = useStore()
  const isMobile = useMobileDetect()
  const dropDownRef = React.useRef<HTMLDivElement>(null)
  const ellipsisRef = React.useRef(null)
  const [showQrCodeModal, setShowQrCodeModal] = React.useState<boolean>(false)
  const [showChecklistActions, setShowChecklistActions] = React.useState<
    boolean
  >(false)
  const [showItemInfo, setShowItemInfo] = React.useState<boolean>(false)
  
  function handleShowActionIcons(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void {
    setShowChecklistActions(!showChecklistActions)
    return
  }

  useClickAway(dropDownRef, (e) => {
    if (!showChecklistActions) return
    setShowChecklistActions(false)
    setShowItemInfo(false)
    })

  const qrUrl: string = useMemo(() => {
    const slug = current?.slug ?? checklistInfo?.slug
    const username = current?.owner?.username ?? checklistInfo?.owner?.username

    let url = routes.checklists.runQr(slug, username, runSlug)
    return url
  }, [current, checklistInfo])


  return (
    <div className="grid grid-flow-col items-center">
      <div>
        <>
          {status === 100 && !item.parent && (
            <div className="bg-transparent text-xs text-green-400 px-2 ml-2 flex">
              {isMobile ? (
                <img alt="check" className="mr-2" src={checkGreenIcon} />
              ) : (
                "completed on"
              )}{" "}
              {DateHelper.localeDate(completedOn)}
              {hasSubItems ? (
                <div className="h-4 w-4 ml-2">
                  <CircularProgressbar
                    strokeWidth={15}
                    styles={buildStyles({
                      pathColor: "#68d391",
                    })}
                    value={status}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </>
        {showProgress && status > 0 && status !== 100 && (
          <div className="flex flex-row items-center">
            <p className="text-xs text-gray2 mr-2">{status}% Completed</p>
            <div className="h-4 w-4">
              <CircularProgressbar
                strokeWidth={15}
                styles={buildStyles({
                  pathColor: "#68d391",
                })}
                value={status}
              />
            </div>
          </div>
        )}
      </div>
      <div>
        {hasAttachments && (
          <div className={isMobile && "ml-2"}>
            <IconButton
              icon="paperclip"
              onClick={(): void => setShowAttachments(!showAttachments)}
              size="sm"
              variant="link"
            />
          </div>
        )}
      </div>
      {isRecurring && (
        <div className="w-9">
          <Tooltip touch={true} tooltipTitle={friendlyDescription}>
            <img
              alt={"clock-wise-icon"}
              className="h-4 w-4 ml-2"
              src={clockWiseIcon}
            />
          </Tooltip>
        </div>
      )}
      <div>
        {(item?.copiedFrom || (qrUrl && runSlug)) && (
          <div ref={ellipsisRef}>
            <ItemActions
              dropDownRef={dropDownRef}
              ellipsisRef={ellipsisRef}
              item={item}
              setShowItemPopover={setShowItemInfo}
              showChecklistActions={showChecklistActions}
              showItemPopover={showItemInfo}
              runSlug={runSlug}
              onShare={() => setShowQrCodeModal(true)}
              isUpdated={isUpdated}
            />
            <Link
              className="btn--secondary mr-1 p-1 flex items-center relative"
              onClick={handleShowActionIcons}
            >
              <Icon name="ellipsis-v" size="lg" />
              {isUpdated && (
                <span className="inline-block w-2 h-2 bg-red-600 rounded-full absolute top-2 left-[8px]"></span>
              )}
            </Link>
          </div>
        )}
      </div>

      {showQrCodeModal && (
        <QrCodeModal
          title="QR code for this item"
          description="Use this QR code, and each time it is scanned by any public user, it will take them to the current active item. (if they exist)."
          onClose={() => setShowQrCodeModal(false)}
          url={qrUrl}
          checklistTitle={checklistTitle}
        />
      )}
    </div>
  )
}
