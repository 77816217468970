import React from "react"
import { checklistTitle, getChecklistOwner } from "~src/common/lib"
import {
  Button,
  ChecklistDescriptionDisplay,
  ChecklistMetadataProps,
  Icon,
  Link,
} from "~src/components"
import { ChecklistMetadata } from "~src/components/ChecklistMetadata"
import { routes } from "~src/routes"
import { ChecklistService } from "~src/services"
import { useStore } from "~src/store"

export interface SubscribedChecklistCardProps {
  checklist: Checklist
  config?: ChecklistMetadataProps["config"]
  onSubRemoved?: () => void
}

export function SubscribedChecklistCard({
  checklist,
  config = { showVisibility: false, showOwner: true },
  onSubRemoved,
}: SubscribedChecklistCardProps): JSX.Element {
  const {
    state: {
      auth: { user },
    },
  } = useStore()

  if (!checklist) return <></>

  const { title, description, slug } = checklist

  const chklOwner = getChecklistOwner(checklist)

  async function handleUnSubOfChecklist(): Promise<void> {
    const result = await ChecklistService.unFollow(slug, btoa(user.username))
    if (result) onSubRemoved()
    return
  }

  return (
    <article className="border rounded-md bg-white px-6 py-6 mb-6">
      <div>
        <div className={"flex justify-between flex-row"}>
          <header className="truncate">
            <h3 className="text-xl leading-tight truncate font-bold">
              <Link
                href={routes.checklists.show(checklist.slug, chklOwner)}
                title={checklistTitle(title)}
                tooltip={checklistTitle(title)}
              >
                {checklistTitle(title)}
              </Link>
            </h3>
          </header>
          <span className="flex h-max-content">
            <Button
              className="h-max-content text-sm btn--hover"
              onClick={handleUnSubOfChecklist}
              variant="secondary"
            >
              <Icon className="mr-3" name="check" />
              Subscribed
            </Button>
            {/* <span className="btn btn--half-disabled text-sm">
              <Icon name="bell" variant="solid" />
            </span> */}
          </span>
        </div>
        {description && (
          <div className="text-sm text-gray-700 w-4/5">
            <ChecklistDescriptionDisplay description={description} textOnly />
          </div>
        )}
      </div>

      <div className="mt-4">
        <ChecklistMetadata checklist={checklist} config={config} />
      </div>
    </article>
  )
}
