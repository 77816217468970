import { useRouter } from "next/router"
import React from "react"
import { useClickAway } from "react-use"
import cs from "classnames"
import {
  AccountDropdown,
  ActiveLink,
  Button,
  Container,
  IconButton,
  Link,
  Logo,
  SearchBox,
  SearchProvider,
  NotificationsDropdown,
  LoginModal,
  SignupModal,
  Tooltip,
  NotSignedUpToolTip,
} from "~src/components"
import { routes } from "~src/routes"
import { useState, useStore } from "~src/store"
import addList from "~src/../public/icons/add-list.svg"
import { MODAL_TYPES } from "~src/common/constants"
import { useShowAuthModals } from "~src/hooks/useShowAuthModals"

export function Nav(): JSX.Element {
  const [showSearch, setShowSearch] = React.useState<boolean>(false)
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false)
  const {
    auth: { state, showLoginModal, showSignupModal },
  } = useState()

  const {
    actions: {
      system: { isSearchResultOpened },
    },
  } = useStore()

  const {
    auth: { user },
  } = useState()

  const router = useRouter()

  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>()

  function handleCloseSearchbox() {
    isSearchResultOpened({ isSearchResultOpened: false })
    setShowSearch(false)
  }

  React.useEffect(() => {
    setIsAuthenticated(state === "authenticated" ? true : false)
  }, [state])

  React.useEffect(() => {
    if (user?.role === "admin" && router.pathname.includes("/admin")) {
      setIsAdmin(true)
    }
  }, [router])

  return (
    <SearchProvider>
      <div className={cs("bg-white w-full z-20 sticky top-0 ")}>
        <nav className=" border-b border-gray-200 py-4 md:py-0">
          <Container className="flex items-center" width="7xl">
            {isAdmin || (
              <div className="inline md:hidden mr-1">
                <NavMobile isAuthenticated={isAuthenticated} />
              </div>
            )}
            <div
              className={`ml-1 sm:ml-0 sm:mr-6 ${
                showSearch && "hidden sm:block"
              }`}
            >
              <Link
                href={
                  isAuthenticated
                    ? routes.generic.dashboardView()
                    : routes.generic.community()
                }
                unstyled
              >
                <div className="flex flex-row items-center" title="ChainList">
                  <Logo className="w-24 md:w-32" width={120} />
                </div>
              </Link>
            </div>
            {isAdmin || (
              <div className="hidden md:flex gap-10 ml-14 font-semibold text-sm">
                {isAuthenticated && (
                  <ActiveLink
                    activeClassName="border-b-4 border-blue-500 text-sm font-semibold text-gray-600 py-4"
                    href={routes.generic.dashboardView()}
                    unstyled
                  >
                    <a
                      className="py-4 flex text-sm font-semibold text-gray-600"
                      href={routes.generic.dashboardView()}
                    >
                      <span>Dashboard</span>
                    </a>
                  </ActiveLink>
                )}
                <ActiveLink
                  activeClassName="border-b-4 border-blue-500 text-sm font-semibold text-gray-600 py-4 "
                  href={routes.generic.community(
                    router?.query?.category as string
                  )}
                  unstyled
                >
                  <a
                    className="py-4 flex text-sm font-semibold text-gray-600"
                    href={routes.generic.community(
                      router?.query?.category as string
                    )}
                  >
                    <span>Community</span>
                  </a>
                </ActiveLink>
                {isAuthenticated && (
                  <ActiveLink
                    activeClassName="border-b-4 border-blue-500 text-sm font-semibold text-gray-600 py-4"
                    href={routes.generic.yourChecklists()}
                    unstyled
                  >
                    <a
                      className="py-4 flex text-sm font-semibold text-gray-600"
                      href={routes.generic.yourChecklists()}
                    >
                      <span>Your Checklists</span>
                    </a>
                  </ActiveLink>
                )}
              </div>
            )}
            <div
              className={`flex items-center ml-auto ${
                showSearch && "flex-1 sm:flex-initial"
              }`}
            >
              {!isAdmin && showSearch && (
                <div className="flex flex-1">
                  <SearchBox onClose={handleCloseSearchbox} />
                </div>
              )}
              <div className={!isAdmin && showSearch ? "hidden sm:flex" : ""}>
                <div className="flex items-center intro-right-navigations gap-1 sm:gap-2">
                  {!isAdmin && !showSearch && isAuthenticated && (
                    <Tooltip tooltipTitle={"Search your lists and all lists"}>
                      <span>
                        <IconButton
                          className={"intro-search-icon cursor-default"}
                          icon="search"
                          iconStyles={"h-4 md:h-5"}
                          onClick={() => setShowSearch(true)}
                          tooltip="hello"
                          variant="link"
                        />
                      </span>
                    </Tooltip>
                  )}
                  {!isAdmin && isAuthenticated && (
                    <>
                      <NotificationsDropdown />
                      <div className="intro-new-checklist-hint intro-create-checklist">
                        <Button
                          href={routes.checklists.new()}
                          icon
                          style={{
                            width: 40,
                            height: 36,
                          }}
                          tooltip="Create new template"
                          variant="link"
                        >
                          <img
                            alt="add list"
                            className="min-w-min"
                            src={addList}
                          />
                        </Button>
                      </div>
                    </>
                  )}

                  {/* TODO: move to actual route /logout */}
                  {isAuthenticated && <AccountDropdown />}
                  {!isAuthenticated && (
                    <span className="pl-2 sm:pl-4">
                      <LoggedOutButtons isAuthenticated={isAuthenticated} />
                    </span>
                  )}
                  {showLoginModal && <LoginModal />}
                  {showSignupModal && <SignupModal />}
                </div>
              </div>
            </div>
          </Container>
        </nav>
      </div>
    </SearchProvider>
  )
}

export function NavMobile({
  isAuthenticated,
}: {
  isAuthenticated: boolean
}): JSX.Element {
  const [expanded, setExpanded] = React.useState<boolean>(false)
  const ref = React.useRef<HTMLDivElement>(null)

  function handleExpand(): void {
    setExpanded(!expanded)
  }

  useClickAway(ref, (e) => {
    if (!expanded) return
    setExpanded(false)
  })
  return (
    <div
      className="flex flex-row items-center relative"
      ref={ref}
    >
      <IconButton
        icon="bars"
        iconStyles={"h-5 md:h-5"}
        onClick={handleExpand}
        variant="link"
      />
      {expanded && (
        <div
          className="absolute shadow-lg rounded-md border border-gray-300 w-56 py-2 z-50 bg-white"
          style={{
            top: 40,
          }}
        >
          <ul className="list-none text-gray-800">
            {isAuthenticated && (
              <li className="px-4 py-2">
                <Link href={routes.generic.dashboardView()} unstyled>
                  Dashboard
                </Link>
              </li>
            )}
            <li className="px-4 py-2">
              <Link href={routes.generic.community()} unstyled>
                Community
              </Link>
            </li>
            {!isAuthenticated && (
              <li className="px-4 py-2">
                <Link href={routes.checklists.new()} unstyled>
                  Create new checklist
                </Link>
              </li>
            )}

            {isAuthenticated && (
              <li className="px-4 py-2">
                <Link href={routes.generic.yourChecklists()} unstyled>
                  Your Checklists
                </Link>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  )
}

function LoggedOutButtons({
  isAuthenticated,
}: {
  isAuthenticated: boolean
}): JSX.Element {
  const { handleModal } = useShowAuthModals()

  return (
    <div className="flex flex-row items-center relative">
      <Link
        className="mr-4 whitespace-nowrap"
        onClick={() => handleModal(MODAL_TYPES.login)}
        tooltip="Log In"
      >
        Log In
      </Link>
      <Button
        onClick={() => handleModal(MODAL_TYPES.signup)}
        tooltip="Register to make and save checklists"
      >
        Sign Up
      </Button>
      {!isAuthenticated && <NotSignedUpToolTip />}
    </div>
  )
}
