import React from "react"
import { inputClassNames } from "~src/common/lib/inputClassNames"
import { Button } from "../Button"
import sparklesIcon from "~public/icons/sparkles.svg"
import { useMobileDetect } from "../useMobileDetect"
import { Icon } from "../Icon"

export interface TextAreaProps
  extends Omit<React.HTMLProps<HTMLTextAreaElement>, "size" | "ref"> {
  size?: "sm" | "md" | "lg"
  maxLimit?: number
  showAiButton?: boolean
  handleAiGeneratedItem?: () => void
  isGenerating?: boolean
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      className,
      size = "md",
      disabled,
      maxLimit,
      onChange,
      showAiButton,
      handleAiGeneratedItem,
      isGenerating,
      ...props
    }: TextAreaProps,
    ref: React.Ref<HTMLTextAreaElement>
  ): JSX.Element => {
    const [isEmpty, setIsEmpty] = React.useState(false)
    function handleOnChange(e: React.ChangeEvent<HTMLTextAreaElement>): void {
      if (!maxLimit || e.currentTarget?.value?.length <= maxLimit) {
        onChange(e)
        if (e.currentTarget.value === "") {
          setIsEmpty(true)
        } else {
          setIsEmpty(false)
        }
      }
    }

    const classes = inputClassNames({ className, disabled, size })
    const isMobile = useMobileDetect()
    return (
      <>
        {showAiButton ? (
          <div className="relative">
            <Button
              className={`absolute ${
                isMobile && "top-20 right-0"
              } top-4 right-4 bg-secondaryGray text-primaryBlue border-none shadow-none`}
              disabled={isGenerating || isEmpty}
              onClick={handleAiGeneratedItem}
              variant="secondary"
            >
              {isGenerating ? (
                <>
                  <Icon
                    className="text-secondaryGreen mr-2 "
                    name="sparkles"
                    spin
                    variant="solid"
                  />
                  Generating...
                </>
              ) : (
                <>
                  <img alt="sparkles" className="mr-2" src={sparklesIcon} />
                  Ai generate checklist
                </>
              )}
            </Button>
            <textarea
              className={classes}
              onChange={handleOnChange}
              ref={ref}
              {...props}
            />
          </div>
        ) : (
          <textarea
            className={classes}
            onChange={handleOnChange}
            ref={ref}
            {...props}
          />
        )}
        {maxLimit && (
          <p className="text-gray-600 text-xs italic">
            Maximum {maxLimit} characters are allowed.
          </p>
        )}
      </>
    )
  }
)
