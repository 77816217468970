import React from "react"
import { ChecklistHelper } from "~src/common/lib"
import { useClickAway } from "react-use"
import {
  Alert,
  AttachmentTiles,
  ChecklistItemBody,
  ExpansionCaret,
  Link,
  Icon,
  ItemActions,
} from "~src/components"
import { checklistTemplateContext } from "~src/contextStores"
import { routes } from "~src/routes"

import { ChecklistItemsHelper } from "@bonsaichecklist/bonsai-utils"
import { AboutToCreateRunModal } from "~src/components"
import { CHECKLIST_VISIBILITY_UNLISTED } from "~src/common/constants"
import { useState } from "~src/store"

export interface ChecklistPreviewProps {
  showInitialRunModel?: boolean
  itemNextRuns?: GenericObject
  onAdd?: (
    item: ChecklistItem,
    checklist: Checklist,
    subItems?: ChecklistItem[],
    itemMap?: ChecklistItemMap
  ) => void
  showCheckBox?: boolean
  setShowInitialRunModel?: (action: boolean) => void
  setInitialRunItem?: (item: GenericObject) => void
  setIsRunZeroInitiated?: (param: boolean) => void
}

export function ChecklistPreview({
  itemNextRuns,
  onAdd,
  showCheckBox = true,
  showInitialRunModel = false,
  setShowInitialRunModel,
  setInitialRunItem,
  setIsRunZeroInitiated,
}: ChecklistPreviewProps): JSX.Element {
  const { checklist, rootItems } = React.useContext(checklistTemplateContext)
  const itemMap = ChecklistItemsHelper.makeItemMap(checklist?.items)

  if (!rootItems?.length) {
    return (
      <Alert
        className="my-8"
        keepOpen
        text="This checklist has no items..."
        type="info"
      />
    )
  }

  return (
    <>
      {rootItems?.map((item) => (
        <Item
          item={item}
          itemMap={itemMap}
          itemNextRuns={itemNextRuns}
          key={item.slug}
          onAdd={onAdd}
          showCheckBox={showCheckBox}
        />
      ))}
      {showInitialRunModel && (
        <div>
          <AboutToCreateRunModal
            checklistSlug={checklist.slug}
            onClose={() => {
              setShowInitialRunModel(false)
              setInitialRunItem({})
            }}
            setIsRunZeroInitiated={setIsRunZeroInitiated}
          />
        </div>
      )}
    </>
  )
}

function Item({
  onAdd,
  itemMap,
  item,
  itemNextRuns,
  showCheckBox = true,
}: {
  onAdd?: (
    item: ChecklistItem,
    checklist: Checklist,
    subItems?: ChecklistItem[],
    itemMap?: ChecklistItemMap
  ) => void
  itemMap: ChecklistItemMap
  item: ChecklistItem
  itemNextRuns?: GenericObject
  showCheckBox?: boolean
}): JSX.Element {
  const {
    auth: { user },
  } = useState()

  const [showAttachments, setShowAttachments] = React.useState<boolean>(false)
  const [showItemInfo, setShowItemInfo] = React.useState<boolean>(false)
  const [expanded, setExpanded] = React.useState<boolean>(false)

  const [showChecklistActions, setShowChecklistActions] = React.useState<
    boolean
  >(false)
  const dropDownRef = React.useRef<HTMLDivElement>(null)
  const ellipsisRef = React.useRef(null)
  const { checklist, checkedItems, handleCheck, isOwner } = React.useContext(
    checklistTemplateContext
  )
  const [isItemCopied, setIsItemCopied] = React.useState(false)

  const subItems = ChecklistItemsHelper.getSubItemsOfParent(itemMap, item.slug)
  const hasItems = subItems?.length > 0

  function handleToggleExpand(): void {
    setExpanded((exp) => !exp)
  }

  function nextRunDate(dateTime: string) {
    const [date] = dateTime?.split(" ")
    return (
      <>
        <span>{date} </span>
      </>
    )
  }

  function handleShowActionIcons(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void {
    setShowChecklistActions(!showChecklistActions)
    return
  }

  const isCopyingItems = Boolean(onAdd)

  const hasAttachments = Boolean(item.attachments?.length)
  const helper = new ChecklistHelper(checklist)

  const linkedListMap = helper.linkedListMap
  const itemClasses = `flex flex-1 flex-row items-start ${
    isItemCopied
      ? "item-copied item-copied-bg item-copied-br text-sm"
      : isCopyingItems
      ? "checklist-item-on-add text-sm"
      : " py-1 hover:bg-gray-100 rounded"
  }`

  const chklOwner = helper.ownerUsername
  const allScheduleUrl = routes.checklists.schedule(checklist.slug, chklOwner)

  useClickAway(dropDownRef, (e) => {
    if (!showChecklistActions) return
    setShowChecklistActions(false)
    setShowItemInfo(false)
  })

  let showItemEllipsis = Boolean(item?.copiedFrom)
  if (item?.copiedFrom?.visibility === CHECKLIST_VISIBILITY_UNLISTED) {
    showItemEllipsis =
      isOwner || item?.copiedFrom?.owner?.username === user?.username
  }

  return (
    <>
      <div className={itemClasses} key={item.slug}>
        <div className="mr-2 w-5 flex-shrink-0">
          {hasItems && (
            <Link
              aria-label={`expand-${item.slug}`}
              className="cursor-pointer"
              onClick={handleToggleExpand}
              unstyled
            >
              <ExpansionCaret expanded={expanded} />
            </Link>
          )}
        </div>

        <div className="flex">
          <div className={showCheckBox && "mr-3"}>
            {showCheckBox && (
              <input
                aria-label={`checklist-item-checkbox-${item.slug}`}
                checked={checkedItems && checkedItems[item.slug] ? true : false}
                className="transform scale-125"
                id={`checklist-item-checkbox-${item.slug}`}
                onClick={(event) =>
                  handleCheck &&
                  handleCheck(
                    item,
                    event,
                    hasItems,
                    setExpanded,
                    expanded,
                    itemMap
                  )
                }
                type="checkbox"
              />
            )}
          </div>
          <div
            className="flex-1"
          >
            <ChecklistItemBody item={item} linkedListMap={linkedListMap} />
          </div>
          <div>
            {itemNextRuns && Boolean(itemNextRuns[item.slug]) && (
              <>
                <Link
                  className="text-sm inline-block text-center text-blue-400 ml-2 mr-3"
                  href={allScheduleUrl}
                  title={`Next run is ${itemNextRuns[item.slug]}`}
                >
                  {nextRunDate(itemNextRuns[item.slug])}
                </Link>
              </>
            )}
          </div>
        </div>

        <div className="flex flex-grow-1 items-center justify-end ml-auto">
          {hasAttachments && (
            <Link onClick={() => setShowAttachments(!showAttachments)}>
              <Icon
                className="ml-1 mr-2 text-blue-500"
                name="paperclip"
                size="sm"
              />
            </Link>
          )}
          {showItemEllipsis && (
            <div ref={ellipsisRef}>
              <ItemActions
                dropDownRef={dropDownRef}
                ellipsisRef={ellipsisRef}
                item={item}
                setShowItemPopover={setShowItemInfo}
                showChecklistActions={showChecklistActions}
                showItemPopover={showItemInfo}
              />
              <Link
                className="btn--secondary mr-1 p-1 flex items-center"
                onClick={handleShowActionIcons}
              >
                <Icon name="ellipsis-v" size="lg" />
              </Link>
            </div>
          )}
          {isCopyingItems && (
            <div>
              <button
                className="add-item ml-1"
                disabled={isItemCopied}
                onClick={() => {
                  setIsItemCopied(true)
                  if (hasItems) return onAdd(item, checklist, subItems, itemMap)
                  onAdd(item, checklist)
                }}
              >
                {isItemCopied ? "Copied" : "Copy"}
              </button>
            </div>
          )}
        </div>
      </div>
      {hasAttachments && showAttachments && (
        <div className="flex flex-row items-center mt-2">
          <div className="w-12 mr-2" />
          <AttachmentTiles
            attachments={item.attachments}
            itemSlug={item.slug}
            size="10rem"
            viewOnly
          />
        </div>
      )}
      {expanded && hasItems && (
        <div className="pl-4">
          {subItems.map((i) => (
            <Item
              item={i}
              itemMap={itemMap}
              itemNextRuns={itemNextRuns}
              key={i.slug}
              onAdd={onAdd}
            />
          ))}
        </div>
      )}
    </>
  )
}
