import React, { useEffect, useState } from "react"
import { useMethods } from "react-use"
import { Button, Icon, IconButton, Input } from "~src/components"
import { RunService } from "~src/services"

enum ChangeStatus {
  Default = "DEFAULT",
  Editing = "EDITING",
  Saving = "SAVING",
}

interface State {
  run?: ChecklistRun
  status?: ChangeStatus
}

function createMethods(state: State) {
  return {
    edit() {
      return { ...state, status: ChangeStatus.Editing }
    },
    cancel() {
      return { ...state, status: ChangeStatus.Default }
    },
    saving() {
      return { ...state, status: ChangeStatus.Saving }
    },
    save(run: ChecklistRun) {
      return {
        ...state,
        status: ChangeStatus.Default,
        run,
      }
    },
  }
}

export function ChecklistRunTitle({
  checklist,
  isOwner,
  run: initialRun,
  showEditIcon = true,
}: {
  checklist: Checklist
  isOwner: boolean
  run: ChecklistRun
  showEditIcon?: boolean
}): JSX.Element {
  const initialState: State = {
    run: initialRun,
    status: ChangeStatus.Default,
  }

  const [state, methods] = useMethods(createMethods, initialState)

  useEffect(() => {
    methods.save(initialRun)
  }, [initialRun.slug, checklist])

  return (
    <>
      {state.status === ChangeStatus.Default && (
        <DisplayTitle
          checklist={checklist}
          isOwner={isOwner}
          onEdit={methods.edit}
          run={state.run}
          showEditIcon={showEditIcon}
        />
      )}
      {state.status === ChangeStatus.Editing && (
        <TitleEdit
          checklist={checklist}
          onCancel={methods.cancel}
          onSave={methods.save}
          onSaving={methods.saving}
          run={state.run}
        />
      )}
      {state.status === ChangeStatus.Saving && (
        <TitleSaving title={state.run?.title} />
      )}
    </>
  )
}

function DisplayTitle({
  checklist,
  isOwner,
  onEdit,
  run,
  showEditIcon,
}: {
  checklist: Checklist
  isOwner: boolean
  onEdit: () => void
  run: ChecklistRun
  showEditIcon: boolean
}): JSX.Element {
  const [runTitle, setRunTitle] = useState<string>(run?.title)

  useEffect(() => {
    setRunTitle(run?.title)
  }, [run.slug, run.title])

  function handleEdit(): void {
    onEdit()
  }

  return (
    <div className="flex items-center max-w-3xl">
      <h1 className="text-2xl font-bold">{runTitle}</h1>
      <span className="bg-transparent text-xs text-green-400 border border-green-400 rounded px-2 ml-3">
        Run
      </span>
      {showEditIcon && isOwner && (
        <div className="ml-4">
          <IconButton
            icon="pencil"
            onClick={handleEdit}
            size="sm"
            variant="link"
          />
        </div>
      )}
    </div>
  )
}

function TitleEdit({
  checklist,
  run,
  onCancel,
  onSave,
  onSaving,
}: {
  checklist: Checklist
  run: ChecklistRun
  onSave: (run: ChecklistRun) => void
  onSaving: () => void
  onCancel: () => void
}): JSX.Element {
  const [currTitle, setCurrTitle] = React.useState<string>(run.title)

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setCurrTitle(e.target?.value)
  }

  function handleCancel(): void {
    onCancel()
  }

  async function handleSave(): Promise<void> {
    onSaving()
    const updatedRun = await RunService.update(checklist.slug, run?.slug, {
      title: currTitle,
    })
    onSave(updatedRun)
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>): void {
    const key = e.key?.toLowerCase()
    switch (key) {
      case "enter":
        handleSave()
        break
      case "escape":
        handleCancel()
        break
      default:
        break
    }
  }

  return (
    <form className="w-full" onSubmit={handleSave}>
      <div>
        <Input
          autoCorrect="off"
          autoFocus
          defaultValue={currTitle}
          maxLength={80}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder="Title"
        />
      </div>
      <div className="flex flex-row items-center my-3">
        <div>
          <Button onClick={handleSave} variant="secondary">
            Save
          </Button>
        </div>
        <div className="ml-auto">
          <Button color="danger" onClick={handleCancel} variant="link">
            Cancel
          </Button>
        </div>
      </div>
    </form>
  )
}

function TitleSaving({ title }: { title: string }): JSX.Element {
  return (
    <h1 className="text-3xl text-gray-500">
      {title}
      <Icon className="ml-4" name="spinner-third" size="sm" spin />
    </h1>
  )
}
