import classNames from "classnames"
import * as cookie from "cookie"
import router from "next/router"
import React, { forwardRef, useCallback, useMemo, useRef } from "react"

import { useClickAway } from "react-use"
import sidebar from "~src/../public/icons/sidebar.svg"
import {
  APP_URL,
  AUTH_COOKIE_NAME,
  AUTH_MODAL_ACTIONS,
  MODAL_TYPES,
  SUBBED_AS_LOCAL_KEY,
} from "~src/common/constants"
import { ChecklistHelper, getQueryValue } from "~src/common/lib"
import {
  Button,
  ConfirmDialog,
  Container,
  Icon,
  IconButton,
  QrCodeModal,
  ScheduleConfirmDialog,
  SubscriberModal,
  TrashChecklistButton,
  useMobileDetect,
} from "~src/components"
import { useToastHide } from "~src/hooks"
import { useShowAuthModals } from "~src/hooks/useShowAuthModals"
import { routes } from "~src/routes"
import { ChecklistService, ScheduleService } from "~src/services"
import { useStore } from "~src/store"
import { MainLayout } from "../"
import EllipsissDropdown from "../ChecklistTemplateLayout/Ellipsisdropdown"
import { MenuItems } from "./components/MenuItems"
import { TimelineHeader } from "./components/TimelineHeader"
interface props {
  checklist: Checklist
  children?: React.ReactNode
  onSchedule?: () => void
  onChecklist?: () => void
  setHideCompletedItems?: (value: boolean) => void
  setRefetchRuns?: (value: boolean | ((value: boolean) => boolean)) => void
}

interface ClickTarget extends EventTarget {
  id: string
}

function TimelineLayoutComponent(
  {
    checklist,
    children,
    onSchedule,
    onChecklist,
    setHideCompletedItems,
    setRefetchRuns,
  }: props,
  ref: unknown
): JSX.Element {
  const {
    actions: {
      checklist: {
        setRedirecting,
        setScheduleConfirmDialog,
        setUnsavedAlert,
        handleUpdateFollowers,
        setVerified,
        unStarChecklist,
        starChecklist,
      },
      auth: { clearModalAction },
      system: {
        showTooltip: showSignupTooltip,
        removeAllToastAlert,
        showToastAlert,
      },
    },
    state: {
      checklist: { checklistVerified },
      auth: { user, state, action },
    },
  } = useStore()

  useToastHide()

  const sideBarRef = useRef<HTMLDivElement>(null)

  const [showSidebar, setShowSidebar] = React.useState(false)
  const [listSchedules, setListSchedules] = React.useState([])
  // const [copied, setCopied] = React.useState<Checklist>(null)
  const [showQrCodeModal, setShowQrCodeModal] = React.useState<boolean>(false)
  const [showDialogFor, setShowDialogFor] = React.useState<
    "copy" | "schedule-copy" | "subscribe"
  >(null)
  const [guestUserSubbed, setGuestUserSubbed] = React.useState(false)

  const [qrUrl, setQrUrl] = React.useState<string>("")
  const slug = checklist?.slug
  const { handleModal } = useShowAuthModals()
  const helper = useMemo(() => new ChecklistHelper(checklist), [checklist])
  const isOwner = helper.isOwner(user)
  const isSubscription = helper.isSubscribed(user)
  const [isTrashed, setIsTrashed] = React.useState<boolean>(
    isOwner && !isSubscription
  )
  const [isFollowedByUser, setIsFollowedByUser] = React.useState<boolean>(
    helper.isSubscribed(user)
  )
  const [showSubscribeModal, setShowSubscribeModal] = React.useState<boolean>(
    false
  )
  const [showCopyConfirmModal, setShowCopyConfirmModal] = React.useState<
    boolean
  >(false)
  const [showConfirmModal, setShowConfirmModal] = React.useState(false)
  const [isStarred, setStarred] = React.useState(false)

  const chklOwner = helper.ownerUsername
  const isMobile = useMobileDetect()
  const subRef = React.useRef(null)

  useClickAway(sideBarRef, (e) => {
    const target = e?.target as ClickTarget
    if (target?.id !== "sidebar" && target?.id !== "sidebarImg") {
      if (showSidebar) setShowSidebar(false)
    }
  })

  React.useEffect(() => {
    if (checklist?.trashedAt) {
      setIsTrashed(true)
    } else setIsTrashed(false)
  }, [checklist])
  React.useEffect(() => {
    setIsFollowedByUser(
      helper.isSubscribed(user) || helper.getGuestSubscribtion(checklist?.slug)
    )

    const starred = user?.starredChecklists?.some(
      (starredChecklist) => starredChecklist.starredChecklist === checklist?.id
    )

    setStarred(starred)
  }, [user, checklist, helper])

  const padding = classNames({
    "px-2": !showSidebar,
  })
  const handleMarkLocalCheck = useCallback(() => {
    setUnsavedAlert(false)
    const localSubs = JSON.parse(localStorage.getItem(SUBBED_AS_LOCAL_KEY))

    const hasSubbedItem = localSubs?.find(
      (item: LocalSubbedTemplate) => item?.slug === checklist?.slug
    )

    const isSubscribed = hasSubbedItem
    const subscriberEmail = hasSubbedItem?.email

    const localRun = JSON.parse(localStorage.getItem("localRun"))

    if (localRun && (isSubscribed || subRef.current)) {
      const occurrences = localRun[checklist?.slug]

      const occurancesArray = Object.keys(occurrences || {})
        .map((item) => item)
        .filter((item) => item !== "0")

      const unscheduled = Object.values(occurrences?.[0]?.slugs || {})

      const data = {
        slug: checklist?.slug,
        username: subscriberEmail || user?.username,
        itemSlug: unscheduled,
        itemValue: true,
      }

      ScheduleService.unscheduleItemCheck(
        checklist?.slug,
        subscriberEmail || user?.username,
        data
      )

      const promises = occurancesArray.map((item) => {
        const slugs = localRun[checklist?.slug][item]?.slugs || {}
        const scheduleId = localRun[checklist?.slug][item]?.scheduleId

        const data = {
          slug: checklist?.slug,
          username: subscriberEmail || user?.username,
          itemSlug: Object.values(slugs || {}),
          itemValue: true,
          scheduleId,
          occurrenceId: item,
        }

        return ScheduleService.upComingChecklistCheck(
          checklist?.slug,
          subscriberEmail || user?.username,
          data
        )
      })
      Promise.all(promises)
        .then(() => {
          subRef.current = null
          setRefetchRuns && setRefetchRuns((prev) => !prev)
        })
        .then(() => {
          localStorage.removeItem("localRun")
        })
    }
  }, [checklist, setRefetchRuns, setUnsavedAlert, user])

  const handleSubscribeClick = useCallback(async () => {
    if (!user) {
      setShowSubscribeModal(true)
      return
    }

    const subscribed = await ChecklistService.follow(checklist?.slug)

    if (!subscribed) return

    handleUpdateFollowers(subscribed?.followers || [])
    setIsFollowedByUser(true)
    subRef.current = true
    handleMarkLocalCheck()
  }, [checklist, handleMarkLocalCheck, handleUpdateFollowers, user])

  async function handleUnSubscribeClick() {
    const unSubscribed = await ChecklistService.unFollow(
      checklist?.slug,
      btoa(user?.username || helper.getSubscriberEmail(checklist?.slug))
    )
    if (!unSubscribed) return

    removeAllToastAlert()
    showToastAlert({
      text: "You have unsubscribed the template",
      type: "success",
    })

    removeGuestSubscription()
    handleUpdateFollowers(unSubscribed?.followers || [])
    setIsFollowedByUser(false)
    setRefetchRuns && setRefetchRuns((prev: boolean) => !prev)
  }

  function handleEditClick() {
    const href = routes.checklists.edit(checklist?.slug, chklOwner)
    router.push(href)
  }

  const getSchedulesList = useCallback(
    async (
      headers: {
        Authorization: string
      },
      action: "schedule-copy" | "subscribe",
      cb: (copySchedules?: boolean) => void
    ): Promise<void> => {
      const { schedules } = await ScheduleService.findAll(slug, headers, {
        page: 1,
        limit: 5,
      })

      if (schedules && schedules.length > 0) {
        setListSchedules(schedules)
        setShowDialogFor(action)
        setScheduleConfirmDialog(true)
        return
      }

      cb()
      return
    },
    [setScheduleConfirmDialog, slug]
  )

  const copyChecklist = useCallback(
    async (copySchedules = false) => {
      setScheduleConfirmDialog(false)

      const copied = await ChecklistService.copy(slug, copySchedules)
      setRedirecting(true)

      if (!copied) {
        setRedirecting(false)
        return
      }

      const href = routes.checklists.edit(copied.slug, user.username)
      router.push(href)
    },
    [setRedirecting, setScheduleConfirmDialog, slug, user]
  )

  const handleScheduleCopyClick = useCallback(async () => {
    setShowCopyConfirmModal(false)

    const cookieData = cookie.parse(document.cookie || "")
    if (cookieData && cookieData[AUTH_COOKIE_NAME]) {
      const headers = {
        Authorization: cookieData[AUTH_COOKIE_NAME],
      }

      getSchedulesList(headers, "schedule-copy", copyChecklist)
    } else {
      copyChecklist(false)
    }
  }, [copyChecklist, getSchedulesList, handleModal, isOwner, user])

  const handleCopyClick = useCallback(async () => {
    if (!isOwner && !user) {
      handleModal(MODAL_TYPES.login, {
        key: "copy",
        description: AUTH_MODAL_ACTIONS.copy,
      })
      return
    }

    const cookieData = cookie.parse(document.cookie || "")
    if (cookieData && cookieData[AUTH_COOKIE_NAME]) {
      const headers = {
        Authorization: cookieData[AUTH_COOKIE_NAME],
      }
      setShowDialogFor("copy")
      setShowCopyConfirmModal(true)
    } else {
      copyChecklist(false)
    }
  }, [copyChecklist, getSchedulesList, handleModal, isOwner, user])

  const attemptToConfirmSubscription = useCallback(
    async (token: string) => {
      const confirmed = await ChecklistService.confirmSubscription(
        checklist?.slug,
        token
      )
      if (confirmed) {
        //write the user and checklist slug inside an array in localstorage
        helper.markAsSubscribe(slug)
        showSignupTooltip(true)
        helper.setleTooltip(true)
        setShowSubscribeModal(true)
        setShowConfirmModal(true)
        setGuestUserSubbed(true)
      }
    },
    [checklist, helper, showSignupTooltip, slug]
  )

  const removeGuestSubscription = () => {
    if (guestUserSubbed) {
      helper.guestUnsubscribe(slug, helper.getSubscriberEmail(slug))
      setGuestUserSubbed(false)
    }
    setShowConfirmModal(false)
  }

  const markChecklistStarBy = useCallback((): void => {
    const isUserIdExist = checklist.starBy.indexOf(user?.id)
    if (isUserIdExist === -1) {
      checklist?.starBy.push(user?.id)
      setStarred(true)
    }
  }, [checklist, user])

  const removeChecklistStarBy = useCallback((): void => {
    const userIdIndex = checklist.starBy.indexOf(user?.id)
    if (userIdIndex > -1) {
      checklist?.starBy.splice(userIdIndex, 1)
      setStarred(false)
    }
  }, [checklist, user])

  function handleOnShare(): void {
    setShowQrCodeModal(true)
  }

  async function handleRestore(): Promise<void> {
    if (!window.confirm("Do you want to restore this checklist?")) return
    await ChecklistService.restoreFromTrash(checklist?.slug)
    setIsTrashed(false)
    showToastAlert({
      text: "Your checklist has been restored successfully.",
      autoHide: true,
      type: "success",
      iconVariant: "solid",
    })
  }

  const handleChecklistFavourite = useCallback(async (): Promise<void> => {
    if (!user) {
      handleModal(MODAL_TYPES.login, {
        key: "star",
        description: AUTH_MODAL_ACTIONS.star,
      })
      return
    }

    if (isStarred) {
      removeChecklistStarBy()
      const result = await unStarChecklist(checklist)

      if (!result) {
        markChecklistStarBy()
        showToastAlert({
          text:
            "There was error in unstaring the checklist, please try again sometime later.",
          autoHide: true,
          type: "danger",
          iconVariant: "solid",
        })
      }
      return
    }

    markChecklistStarBy()
    const result = await starChecklist(checklist)

    if (!result) {
      removeChecklistStarBy()
      showToastAlert({
        text:
          "There was error in starring the checklist, please try again sometime later.",
        autoHide: true,
        type: "danger",
        iconVariant: "solid",
      })
    }
  }, [
    checklist,
    handleModal,
    isStarred,
    markChecklistStarBy,
    removeChecklistStarBy,
    showToastAlert,
    starChecklist,
    unStarChecklist,
    user,
  ])

  React.useEffect(() => {
    setVerified(Boolean(checklist?.verified))
    handleMarkLocalCheck()
  }, [checklist, handleMarkLocalCheck, setVerified])

  React.useEffect(() => {
    if (state !== "authenticated") return
    // getCopiedChecklist()

    if (action?.key === "subscribe") {
      clearModalAction()

      handleSubscribeClick()
    }

    if (action?.key === "copy") {
      clearModalAction()
      handleCopyClick()
    }

    if (action?.key === "star") {
      clearModalAction()
      handleChecklistFavourite()
    }
  }, [
    action,
    clearModalAction,
    handleChecklistFavourite,
    handleCopyClick,
    handleSubscribeClick,
    state,
  ])

  const hasSubscribed = helper.getGuestSubscribtion(checklist?.slug)
  React.useEffect(() => {
    const chklOrActivityTab =
      router?.asPath === routes.checklists.show(slug, chklOwner) ||
      router?.asPath === routes.checklists.activities(slug, chklOwner)

    const QR_URL = chklOrActivityTab
      ? APP_URL + routes.checklists.checklistQR(slug, chklOwner)
      : APP_URL + routes.checklists.qr(slug, chklOwner, user?.qrSlug)

    setQrUrl(QR_URL)

    const comingFromSubVerify = getQueryValue(router?.query?.t)

    if (comingFromSubVerify) {
      attemptToConfirmSubscription(comingFromSubVerify)
    }

    if (hasSubscribed && !user) {
      setGuestUserSubbed(true)
    }
  }, [attemptToConfirmSubscription, checklist, chklOwner, helper, slug, user])

  function menuButtons() {
    return (
      <>
        {!isOwner && (
          <span className="flex h-max-content">
            {(state === "authenticated" && isFollowedByUser) ||
            guestUserSubbed ? (
              <Button
                className="h-max-content w-max-content text-sm rounded-r btn--hover"
                onClick={handleUnSubscribeClick}
                variant="secondary"
              >
                <Icon className="mr-3" name="check" />
                Subscribed
              </Button>
            ) : (
              <Button
                className="h-max-content w-max-content text-sm"
                onClick={handleSubscribeClick}
                variant="secondary"
              >
                <Icon className="mr-3" name="bell" />
                Subscribe to checklist
              </Button>
            )}
          </span>
        )}

        <span className="flex h-max-content w-max-content">
          <IconButton
            className={"rounded-r-none text-sm"}
            icon={"star"}
            iconVariant={isStarred ? "solid" : "light"}
            onClick={handleChecklistFavourite}
            tooltip={"Add this checklist to Your Stars"}
            variant="secondary"
          />
          <span className="btn btn--half-disabled text-sm">
            {checklist?.starBy?.length || 0}
          </span>
        </span>

        {isOwner && !isSubscription && (
          <Button
            className="h-max-content w-max-content text-sm"
            onClick={handleEditClick}
            tooltip={"Edit your checklist"}
            variant="secondary"
          >
            <Icon className="m-0 sm:mr-3" name="pencil" />
            <span className="hidden sm:inline">Edit</span>
          </Button>
        )}
        {!isTrashed && !checklist?.followers?.length && (
          <TrashChecklistButton
            checklist={checklist}
            handleRedirecting={setRedirecting}
          />
        )}
        {isTrashed && isOwner && (
          <div className={"flex justify-between"}>
            <Button
              className="h-max-content w-max-content text-sm"
              onClick={handleRestore}
              tooltip={"Restore this checklist"}
              variant="secondary"
            >
              <Icon className="mr-3" name="trash-restore" variant="regular" />
              Restore
            </Button>
          </div>
        )}
        <EllipsissDropdown
          isOwner={isOwner}
          onChecklist={onChecklist}
          onCopy={handleCopyClick}
          onSchedule={onSchedule}
          onShare={handleOnShare}
        />
      </>
    )
  }

  function mobileMenuButtons() {
    return (
      <div className={`${showSidebar && "px-2"} flex justify-between`}>
        <div className="flex gap-3">
          {isMobile && (
            <div className="flex col-end-7 col-span-2 space-x-3 h-9  intro-subscribe-star">
              {(!isOwner && state === "authenticated" && isFollowedByUser) ||
              guestUserSubbed ? (
                <span className="flex h-max-content">
                  <Button
                    className="h-max-content w-max-content text-sm rounded-r btn--hover"
                    onClick={handleUnSubscribeClick}
                    variant="secondary"
                  >
                    <Icon className="mr-3" name="plus" />
                    Subscribed
                  </Button>
                </span>
              ) : (
                !isOwner && (
                  <Button
                    className="h-max-content w-max-content text-sm"
                    onClick={handleSubscribeClick}
                    variant="secondary"
                  >
                    <Icon className="mr-3" name="plus" />
                    Subscribe
                  </Button>
                )
              )}
              {isOwner && !isSubscription && (
                <Button
                  className="h-max-content w-max-content text-sm"
                  onClick={handleEditClick}
                  tooltip={"Edit your checklist"}
                  variant="secondary"
                >
                  <Icon className="m-0 sm:mr-3" name="pencil" />
                  <span className="hidden sm:inline">Edit</span>
                </Button>
              )}

              <span className="flex h-max-content w-max-content">
                <IconButton
                  className={"rounded-r-none text-sm"}
                  icon={"star"}
                  iconVariant={isStarred ? "solid" : "light"}
                  onClick={handleChecklistFavourite}
                  tooltip={"Add this checklist to Your Stars"}
                  variant="secondary"
                />
                <span className="btn btn--half-disabled text-sm">
                  {checklist?.starBy?.length || 0}
                </span>
              </span>
              {!isTrashed && !checklist?.followers?.length && (
                <TrashChecklistButton
                  checklist={checklist}
                  handleRedirecting={setRedirecting}
                />
              )}
              {isTrashed && isOwner && (
                <div className={"flex justify-between"}>
                  <Button
                    className="h-max-content w-max-content text-sm"
                    onClick={handleRestore}
                    tooltip={"Restore this checklist"}
                    variant="secondary"
                  >
                    <Icon
                      className="mr-3"
                      name="trash-restore"
                      variant="regular"
                    />
                    Restore
                  </Button>
                </div>
              )}
              <EllipsissDropdown
                isOwner={isOwner}
                onChecklist={onChecklist}
                onCopy={handleCopyClick}
                onSchedule={onSchedule}
                onShare={handleOnShare}
              />
            </div>
          )}
        </div>
        <div className="lg:hidden">
          <Button
            id="sidebar" // do not remove id, until you know what you are doing
            onClick={() => setShowSidebar(!showSidebar)}
            variant="secondary"
          >
            <img
              alt="sidebar"
              className={`transform ${showSidebar && "rotate-180"}`}
              id="sidebarImg"
              src={sidebar}
            />
          </Button>
        </div>
      </div>
    )
  }

  return (
    <MainLayout>
      <div className="bg-white w-full border-b border-gray-200 pt-8">
        <div className={`max-w-7xl mx-auto ${padding}`}>
          {/** Header */}
          <TimelineHeader
            checklist={checklist}
            checklistVerified={checklistVerified}
            mobileMenuButtons={mobileMenuButtons}
            setHideCompletedItems={setHideCompletedItems}
            showSidebar={showSidebar}
            sideBarRef={sideBarRef}
          />
          {/** Menu */}
        </div>
        <div className="border-t border-gray-200 w-12/12">
          <div className={`max-w-7xl mx-auto ${padding}`}>
            <MenuItems
              checklist={checklist}
              isMobile={isMobile}
              menuButtons={menuButtons}
              showSidebar={showSidebar}
            />
          </div>
        </div>
      </div>
      <Container width="7xl">{children}</Container>
      {showDialogFor === "schedule-copy" && (
        <ScheduleConfirmDialog
          action="schedule-copy"
          checklist={checklist}
          handleCopyConfirm={copyChecklist}
          schedules={listSchedules}
          showSeeMore
        />
      )}
      
      {showDialogFor === "copy" && showCopyConfirmModal && (
        <ConfirmDialog
          message="Do you want to make your own copy of this list?"
          onClose={() => setShowCopyConfirmModal(false)}
          onCopy={handleScheduleCopyClick}
        />
      )}

      {showQrCodeModal && (
        <QrCodeModal checklistTitle={checklist.title} onClose={() => setShowQrCodeModal(false)} url={qrUrl} />
      )}

      {showSubscribeModal && (
        <SubscriberModal
          checklist={checklist}
          onClose={() => {
            setShowSubscribeModal(false)
          }}
          showConfirmModal={showConfirmModal}
        />
      )}
    </MainLayout>
  )
}

export const TimelineLayout = forwardRef(TimelineLayoutComponent)
