import React from "react"
import * as yup from "yup"
import { MIN_USERNAME_LENGTH } from "~src/common/constants"
import { validateUsername } from "~src/common/lib/validateUsername"
import { Icon, Input, InputProps } from "~src/components"
import { Alert } from "~src/components/Alert"
import { AuthService } from "~src/services"
import styles from "./SetUsernameField.module.css"

export const USERNAME_VALIDATION_SCHEMA = yup
  .string()
  .min(MIN_USERNAME_LENGTH)
  .test(
    "validate-username",
    "Username can only be combination of alphanumeric and _ or -",
    validateUsername
  )
  .required()

export interface SetUsernameFieldProps extends InputProps {
  onChange: (e: React.FormEvent<HTMLInputElement>) => void
  isUsernameAvailable: boolean
  setIsUsernameAvailable: React.Dispatch<React.SetStateAction<boolean>>
  error?: string
  value: string
  setErrors?: (errors: GenericObject) => void
}

let timeout: ReturnType<typeof setTimeout>

export function SetUsernameField({
  onChange,
  isUsernameAvailable,
  setIsUsernameAvailable,
  error,
  value,
  setErrors,
  ...props
}: SetUsernameFieldProps): JSX.Element {
  const [taken, setTaken] = React.useState(false)

  const [userNameError, setUserNameError] = React.useState<string>()

  async function checkIfUsernameAvailable(username: string): Promise<void> {
    const isAvailable = await AuthService.isUsernameAvailable(
      username.toLowerCase()
    )
    setIsUsernameAvailable(isAvailable)
    setTaken(!isAvailable)
  }

  function resetUsernameStates(): void {
    setIsUsernameAvailable(false)
    setTaken(false)
  }

  function handleUsernameKeyup(e: React.FormEvent<HTMLInputElement>): void {
    const username = e.currentTarget.value
    if (validateUsername(username)) {
      if (userNameError) setUserNameError("")
      clearTimeout(timeout)
      setErrors && setErrors({})
      if (username?.length >= MIN_USERNAME_LENGTH) {
        timeout = setTimeout(() => checkIfUsernameAvailable(username), 600)
      } else {
        resetUsernameStates()
      }
      return
    } else if (!username) return

    setIsUsernameAvailable(false)
    setUserNameError(
      "Username can only be combination of alphanumeric and _ or -"
    )
    return
  }

  function handleSpaceValidation(e: any): void {
    if (e.keyCode === 32) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  React.useEffect((): (() => void) => (): void => clearTimeout(timeout), [])

  return (
    <div className="my-4">
      <div className="relative">
        {(error || userNameError) && (
          <div className="mb-2 text-red-600">
            {error || userNameError}
          </div>
        )}
        {!error && !userNameError && taken && (
          <>
            <Icon
              className={`${styles["username-icon"]} absolute text-red-600`}
              name="times-circle"
              title="This username is already taken."
            />
          </>
        )}
        {!error && !userNameError && isUsernameAvailable && (
          <Icon
            aria-label="username available"
            className={`${styles["username-icon"]} text-green-600`}
            name="check-circle"
          />
        )}
        <Input
          autoComplete="off"
          autoCorrect="off"
          className="inline-block"
          id="username"
          onBlur={(): void => !value && resetUsernameStates()}
          onChange={onChange}
          onKeyDown={handleSpaceValidation}
          onKeyUp={handleUsernameKeyup}
          placeholder="Username"
          value={value}
          {...props}
        />
      </div>
      {taken && <Alert className="mt-2">This username is already taken.</Alert>}
    </div>
  )
}
