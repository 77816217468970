import React, { useState } from "react"
import { DateHelper } from "@bonsaichecklist/bonsai-utils"
import ReactDatePicker from "react-datepicker"
import { useClickAway } from "react-use"
import dayjs from "dayjs"

import { RECURRENCE_PATTERNS, ScheduleHelper } from "~src/common/lib"
import { Icon } from "~src/components"
import { useStore } from "~src/store"
import CustomOptionsModal from "./CustomOptionsModal"
import { routes } from "~src/routes"
import { useRouter } from "next/router"

type RepeatUntil = "forever" | "on-date"

type Props = {
  itemSlug: string
}

export function ScheduledOptions({ itemSlug }: Props): JSX.Element {
  const optionsDropDownRef = React.useRef<HTMLDivElement>()

  const {
    state: {
      checklist: { checklistScheduleMap, current },
    },
    actions: {
      checklist: { setStartedAt, setFrequency, setUntilDate, deleteUntilDate },
    },
  } = useStore()

  const schedule = checklistScheduleMap[itemSlug]
  const startedAt = checklistScheduleMap[itemSlug]?.startedAt
  const recurrencePattern = checklistScheduleMap[itemSlug]?.recurrencePattern
  const untilDate = checklistScheduleMap[itemSlug]?.until
  const customOption = checklistScheduleMap[itemSlug]?.customOptions
  const router = useRouter()
  let friendlyDescription = ""

  if (customOption) {
    friendlyDescription = new ScheduleHelper(schedule).friendlyDescription
  }

  const repeatOptions: {
    [key: string]: { title: string; value: RECURRENCE_PATTERNS }
  } = {
    noRepeat: {
      title: "Doesn't repeat",
      value: "single-occurrence",
    },

    weekly: {
      title: `Weekly on the first {Day}`,
      value: "weekly",
    },

    "weekday-of-month": {
      title: `Monthly on the first {Day}`,
      value: "weekday-of-month",
    },
    "quarterly-weekday-of-month": {
      title: `Quarterly on the first {Day}`,
      value: "quarterly-weekday-of-month",
    },
    custom: {
      title: friendlyDescription || "Custom",
      value: "custom",
    },
  }

  const [until, setUntil] = useState<Date | undefined>(
    untilDate ? new Date(untilDate) : undefined
  )
  const [isDateValid, setIsDateValid] = useState<boolean>(true)
  const [pattern, setPattern] = useState<{
    title: string
    value: RECURRENCE_PATTERNS
  }>(
    repeatOptions[recurrencePattern] || {
      title: "Doesn't repeat",
      value: "single-occurrence",
    }
  )
  const [expandOptions, setExpandOptions] = useState(false)
  const [repeatUntil, setRepeatUntil] = useState<RepeatUntil>(
    untilDate ? "on-date" : "forever"
  )
  const [showCustomModal, setShowCustomModal] = useState<boolean>(false)

  useClickAway(optionsDropDownRef, () => {
    if (expandOptions) {
      setExpandOptions(false)
    }
  })

  function handleEndDate(date: Date) {
    if (
      startedAt &&
      pattern.value !== "single-occurrence" &&
      repeatUntil === "on-date"
    ) {
      setUntil(date)
      setUntilDate({ slug: itemSlug, date })
    }
  }

  function handleDateValidation(date: Date): void {
    const dateInvalid = !date || DateHelper.isPast(date)
    setIsDateValid(!dateInvalid)
  }

  function handleToggleRepeatOptions() {
    setExpandOptions((prev) => !prev)
  }

  function handleChangeFrequency({
    title,
    value,
  }: {
    title: string
    value?: RECURRENCE_PATTERNS
  }): void {
    setFrequency({ slug: itemSlug, pattern: { title, value } })
    setPattern({ title, value })
    setExpandOptions(false)

    if (value === "custom") {
      setShowCustomModal(true)
    }
  }

  const handleChangeRepeatUntil = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (e.currentTarget.value === "forever") {
      deleteUntilDate({ slug: itemSlug })
      setUntil(undefined)
    }

    setRepeatUntil(e.currentTarget.value as RepeatUntil)
  }

  const getTommorrowDate = React.useCallback(
    () => new Date(new Date().setDate(new Date().getDate() + 1)),
    []
  )

  React.useEffect(() => {
    if (friendlyDescription) {
      setPattern({
        title: friendlyDescription,
        value: "custom",
      })
    }
  }, [until, customOption, startedAt])

  const isEditing =
    routes.checklists.edit(current?.slug, current?.owner?.username) ===
    router.asPath

  return (
    <>
      {isEditing && (
        <h2 className="font-bold text-sm text-black mb-2">
          Next schedule date:
          <span className="font-medium mx-1">
            {dayjs(schedule?.nextRunAt).format("MM/DD/YYYY")}
          </span>
        </h2>
      )}
      <div className="flex items-center gap-6 pb-6 md:flex-nowrap flex-wrap">
        <div className="w-full md:w-auto md:flex-1">
          <h4 className="font-bold text-sm text-black mb-2">Start Date</h4>
          <ReactDatePicker
            className="input input--md placeholder-gray-700"
            popperClassName="z-10"
            customInput={<CustomDateInput aria-label="start date" />}
            dateFormat="MM/dd/yyyy"
            dropdownMode="select"
            minDate={getTommorrowDate()}
            onChange={(date) => {
              const dateToSet = startedAt
                ? date
                : new Date(date.toString()).setHours(9, 0, 0, 0)

              handleDateValidation(dateToSet as Date)
              setStartedAt({
                slug: itemSlug,
                startDate: new Date(dateToSet as number),
              })
            }}
            placeholderText="Choose starting date"
            selected={startedAt ? new Date(startedAt) : new Date()}
            shouldCloseOnSelect={false}
            showMonthDropdown
            showYearDropdown
          />
        </div>
        <div className="flex-1  relative" ref={optionsDropDownRef}>
          <h4 className="text-sm font-bold text-black mb-2">Repeat</h4>
          <div
            className="input input--md h-12 flex items-center cursor-pointer w-full select-none"
            onClick={handleToggleRepeatOptions}
          >
            <span className="text-sm font-normal text-black w-full capitalize-first">
              {pattern?.title.replace("{Day}", DateHelper.weekday(startedAt))}
            </span>

            <Icon className=" text-primaryBlue" name="chevron-down" />
          </div>

          {expandOptions && (
            <div
              className=" border border-gray4 rounded-b-md 
              pt-4 px-4 absolute z-10 bg-white w-full"
            >
              {Object.keys(repeatOptions).map((option, index) => (
                <div
                  className="flex items-center justify-between pb-4 cursor-pointer gap-3"
                  key={index}
                  onClick={() => handleChangeFrequency(repeatOptions[option])}
                >
                  <p
                    className={`text-15px ${
                      repeatOptions[option].value === pattern.value
                        ? "font-bold text-primaryBlue"
                        : "font-normal text-black"
                    }  capitalize-first`}
                  >
                    {repeatOptions[option].title.replace(
                      "{Day}",
                      DateHelper.weekday(startedAt)
                    )}
                  </p>

                  {repeatOptions[option]?.value === "custom" &&
                    Boolean(
                      friendlyDescription || customOption?.monthRepeat?.title
                    ) && <Icon className="text-primaryBlue" name="pencil" />}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {pattern.value !== "single-occurrence" && (
        <div className="flex flex-col sm:flex-row gap-3 sm:gap-8 my-6">
          <div className="flex items-center">
            <label>
              <input
                checked={repeatUntil === "forever"}
                className="mr-4"
                disabled={!startedAt}
                name={itemSlug}
                onChange={handleChangeRepeatUntil}
                type="radio"
                value="forever"
              />{" "}
              Forever
            </label>
          </div>
          <div className="flex items-center">
            <label className="flex items-center mr-4">
              <input
                checked={repeatUntil === "on-date"}
                className="mr-4"
                disabled={!startedAt}
                name={itemSlug}
                onChange={handleChangeRepeatUntil}
                type="radio"
                value="on-date"
              />{" "}
              Until{" "}
            </label>
            <ReactDatePicker
              className="input input--sm"
              popperClassName="z-10"
              customInput={<CustomDateInput aria-label="start date" />}
              dateFormat="MM/dd/yyyy"
              disabled={repeatUntil !== "on-date"}
              dropdownMode="select"
              minDate={dayjs(startedAt).add(1, "day").toDate()} // Prevent same date selection as start date
              onChange={(date) => {
                handleDateValidation(date as Date)
                handleEndDate(date as Date)
              }}
              placeholderText="End Date"
              selected={until}
              showMonthDropdown
              showYearDropdown
              timeInputLabel="Time:"
            />
          </div>
        </div>
      )}

      {showCustomModal && (
        <CustomOptionsModal
          itemSlug={itemSlug}
          setPattern={setPattern}
          setShowCustomModal={setShowCustomModal}
        />
      )}
    </>
  )
}

function CustomDateInput(props: GenericObject): JSX.Element {
  return <input type="text" {...props} readOnly />
}
