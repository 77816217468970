import { SizeProp } from "@fortawesome/fontawesome-svg-core"
import classnames from "classnames"
import React from "react"
import { Icon, IconProps, Link, Tooltip } from "~src/components"

export interface ButtonProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, "size"> {
  children?: React.ReactNode
  className?: string
  destructive?: boolean
  disabled?: boolean
  download?: string
  fullWidth?: boolean
  href?: string
  icon?: boolean
  leftIcon?: IconProps["name"]
  loading?: boolean
  muted?: boolean
  rightIcon?: IconProps["name"]
  size?: "sm" | "md" | "lg"
  target?: string
  type?: "button" | "submit" | "reset"
  variant?: "primary" | "secondary" | "link"
  tooltip?: string
}

export function Button({
  children,
  className,
  destructive,
  disabled,
  download,
  fullWidth,
  href,
  icon,
  // TODO: support components as well
  leftIcon,
  loading,
  muted,
  onClick,
  rightIcon,
  size = "md",
  target,
  type = "button",
  variant = "primary",
  tooltip,
  ...props
}: ButtonProps): JSX.Element {
  // Disable while loading
  disabled = disabled || loading

  const classes = classnames(
    "btn",
    {
      "btn--disabled": disabled,
      "btn--primary": variant === "primary",
      "btn--secondary": variant === "secondary",
      "btn--link": variant === "link",
      "btn--muted": muted,
      "btn--sm": size === "sm",
      "btn--lg": size === "lg",
      "btn--full": fullWidth,
      "btn--icon": icon,
      "btn--destructive": destructive,
    },
    className
  )

  let btnSize: SizeProp = "1x"
  if (size === "sm") btnSize = "sm"
  if (size === "lg") btnSize = "lg"

  const contents = (
    <>
      {leftIcon && (
        <Icon className="left-icon" fixedWidth name={leftIcon} size={btnSize} />
      )}
      {children}
      {rightIcon && (
        <Icon
          className="right-icon"
          fixedWidth
          name={rightIcon}
          size={btnSize}
        />
      )}
    </>
  )

  const btn = (
    <button
      className={classes}
      disabled={disabled}
      onClick={!disabled ? onClick : undefined}
      type={type}
      {...props}
    >
      {contents}
    </button>
  )

  let btnWithTooltip = btn
  if (tooltip) {
    btnWithTooltip = <Tooltip tooltipTitle={tooltip}>{btn}</Tooltip>
  }

  if (href) {
    return (
      <Link
        disabled={disabled}
        download={download}
        href={href}
        target={target}
        unstyled
      >
        {btnWithTooltip}
      </Link>
    )
  }

  return btn
}
