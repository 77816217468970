import classnames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"
import {
  connectSearchBox,
  SearchBox as AlgoliaSearchBox,
} from "react-instantsearch-dom"
import { SearchResults } from "~src/components"
import { INITIATE_SEARCH_ON_CHARS } from "~src/common/constants"
import { IconButton } from "~src/components/IconButton"
import { useWarnIfUnsavedChanges } from "~src/hooks"
import { useStore } from "~src/store"

// timeout for done typing approach i.e let user first complete typing and then hit search api call
let timeout: ReturnType<typeof setTimeout>

const Backdrop = connectSearchBox(({ refine, currentRefinement }) => {
  if (!currentRefinement) return <></>
  const classes = classnames(
    // Make the backdrop white on mobile to cover up background elements.
    "opacity-100 bg-white",
    // On desktop, we want a light gray BG color that is transparent.
    "sm:bg-gray-500 sm:opacity-25",
    // Make the backdrop extend the entire background of the page
    "fixed  w-full h-full top-0 left-0 right-0",
    // Make sure the backdrop is BEHIND the other search elements
    // so that it overlays all content BUT the search elements in
    // a visually pleasing way. The search elements are "z-20".
    "z-10"
  )

  return (
    <AnimatePresence>
      <motion.div
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        initial={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <div className={classes} onClick={() => refine("")}></div>
      </motion.div>
    </AnimatePresence>
  )
})

function UnconnectedSearchBox({
  onClose,
  currentRefinement,
  handleOnClick,
  refine,
  showSearchClose = true,
  disableOuterScroll = true,
}: {
  onClose: () => void
  currentRefinement: string
  handleOnClick?: (slug: string) => void
  refine: (q: string) => void
  showSearchClose?: boolean
  disableOuterScroll?: boolean
}): JSX.Element {
  const [shouldFormReset, setShouldFormReset] = React.useState(false)

  const {
    state: {
      checklist: { unsavedChanges },
    },
    actions: {
      checklist: { reset },
      system: { isSearchResultOpened },
    },
  } = useStore()

  React.useEffect(() => {
    isSearchResultOpened({
      isSearchResultOpened:
        currentRefinement?.length >= INITIATE_SEARCH_ON_CHARS,
      disableOuterScroll,
    })
  }, [currentRefinement])

  React.useEffect(() => {
    return function cleanup(): void {
      if (shouldFormReset) reset()
    }
  }, [reset, shouldFormReset])

  useWarnIfUnsavedChanges(unsavedChanges, setShouldFormReset)

  const showResults = Boolean(currentRefinement)

  const container = classnames(
    // Make sure the search elements live ABOVE the background
    "z-20",
    // Make sure the search components flow horizontally so they're in a
    // line with each other.
    "flex flex-1 items-center",
    // Make the root relative so we can properly absolute position the
    // results dropdown.
    "relative"
  )
  const searchBoxContainer = classnames(
    // Make sure items are laid horizontally within the parent.
    "flex flex-1 items-center"
  )
  const resultsContainer = classnames(
    // On desktop, give the results box a subtle background drop shadow
    "sm:shadow-xs",
    // Hide the results by default by making them translucent
    showResults ? "opacity-100" : "opacity-0",
    // Position the overlay relative to the search box.
    "absolute float-left top-full right-0",
    // Make the results background white.
    "bg-white",
    // Round the results box on desktop as it isn't full width:
    "sm:rounded-md",
    // Make the results dropdown a pleasing width so that it doesn't
    // extend the full screen on desktop.
    "sm:w-96",
    // On mobile we want the results to be full width, so make them also attach
    // to the left of the screen but undo this for larger screen sizes.
    "left-0 sm:left-auto",
    // Bump around the results dropdown on desktop to better align with the
    // search box.
    "sm:mt-4 sm:mr-8"
  )

  const handleOnChange = (e: React.SyntheticEvent<HTMLInputElement>): void => {
    const query = e.currentTarget.value
    clearTimeout(timeout)
    if (query?.length >= 2) timeout = setTimeout(() => refine(query), 300)
  }

  React.useEffect(() => {
    return () => clearTimeout(timeout)
  }, [])

  return (
    <>
      <Backdrop />
      <div className={container}>
        <div className={searchBoxContainer}>
          <div className="flex-1">
            <AlgoliaSearchBox
              autoFocus
              onChange={handleOnChange}
              searchAsYouType={false}
            />
          </div>
          {showSearchClose && (
            <IconButton
              className="ml-2"
              icon="times"
              onClick={onClose}
              variant="link"
            />
          )}
        </div>
        {showResults && (
          <div className={resultsContainer}>
            <SearchResults onClickCallback={handleOnClick} onClose={onClose} />
          </div>
        )}
      </div>
    </>
  )
}

export const SearchBox = connectSearchBox(UnconnectedSearchBox)
